import React, { useEffect, useState, useContext } from 'react'
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import UploadSelectorContext from '../../context/UploadSelectorContext';
import LoadingContext from '../../context/LoadingContext';
import { GET_UPLOAD_RVIS } from '../../graphql/queries';
import { Select } from '..';

const UploadSelector = ({ }) => {
    const { data, loading } = useQuery(GET_UPLOAD_RVIS)
    const [items, setItems] = useState([])
    const { value, setValue } = useContext(UploadSelectorContext)
    const { setLoading } = useContext(LoadingContext)

    useEffect(() => {
        if (data) {
            const items = data.uploadrvis.map(x => ({ 
                value: moment(x.date_start).format("MMMM YYYY"), 
                key: x.id 
            }))
            setItems([
                ...items
            ])
            const lastMonthSelection = items.find(it => it.key === window.localStorage.getItem("lastMonthSelection"))
            setValue(lastMonthSelection ? lastMonthSelection.key : items[0] && items[0].key)
        }
    }, [data])

    return <Select items={items} handleChange={value => {
        setValue(value)
        window.localStorage.setItem("lastMonthSelection", value)
    }} mode={null} value={value} disabled={items.length === 0 ? true : false} />
}

export default UploadSelector;