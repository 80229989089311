import React from 'react'
import styled from 'styled-components'
import { gradients } from '../../utils/gradients'

const CardGlobalResult = ({ title, result, line, slim }) => {
    const color = line === "green" ? "newgreen" : (line === "red" ? "newred" : "")
    return (
        <Wrapper slim={slim}>
            {!result && <Line color={color} />}
            <Row>
                <Left slim={slim}>
                    <h4>{title}</h4>
                </Left>
                {result && <Right>
                    <span>{result}</span>
                </Right>}
            </Row>
        </Wrapper>
    )
}

const Line = styled.div`
    height: 5px;
    width: 100%;
    background: ${({ color }) => gradients[color]};
`

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    height: ${({slim}) => slim ? "35px" : ""};
`

const Row = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
`
const Left = styled.div`
    display: flex;
    flex: 1;
    h4{
        margin: ${({slim}) => slim ? "0 1em" : "1em"};
    }
`
const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    background: linear-gradient(135deg, rgba(131,222,176,1) 0%, rgba(13,109,97,1) 100%);
    color: white;
    border-radius: 8px;
    height: 100%;
`

export default CardGlobalResult