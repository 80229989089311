import React from 'react'

const TableLabel = ({ dark, children }) => {
    return (
        <label style={{
            ...styles.label,
            ...(dark ? styles.dark : {})
        }}>{ children }</label>
    )
}

const styles = {
    label: {
        fontFamily: "Poppins",
        fontWeight: "600",
        color: "#999"
    },
    dark: {
        color: "#666"
    }
}
export default TableLabel