import React, { useState } from 'react';
import { ConditionalItem, PrivateComponent } from '../../component';
import { Input, Card, Button, Select } from '../../libraryComponent';
import { itemsFirstSelect, itemsThirdSelect, itemsFifthSelect, itemsSeventhSelect, itemsNinethSelect } from '../../utils/constantes';
import { Row, Col } from 'antd';
import styled from 'styled-components'
import { get } from "lodash"

const SingleCondition = ({ condition, conditionals, i, kpiList, setConditionals, ready }) => {

    const removeCondition = i => {
        setConditionals([
            ...conditionals.filter((c, j) => i !== j)
        ])
    }
    const user = JSON.parse(localStorage.getItem("user"))
    const locked = get(user, "role.type") !== "admin"

    return (
        <Card style={{ marginBottom: "2em" }}>
            <Title>SI</Title>
            <Row gutter={[12, 12]}>
                {/* 1Select */}
                <Col lg={condition.select1 === "BONUS" ? 8 : condition.arrayKpisSi.includes("SPECIAL") ? 4 : 6} md={12} sm={24}>
                    <Label>Tipo de KPI</Label>
                    <ConditionalItem
                        items={itemsFirstSelect}
                        value={condition.select1 || undefined}
                        disabled={locked}
                        handleChange={value => {
                            setConditionals(
                                conditionals.map((cnd, j) => {
                                    if (i !== j) { return cnd }
                                    else {
                                        return ({
                                            ...cnd,
                                            select1: value,
                                            arrayKpisSi: [],
                                            arrayConditionsSi: null,
                                            intSi: 0,
                                            select5: null,
                                            arrayKpisEntonces: [],
                                            intEntonces: 0
                                        })
                                    }
                                })
                            )
                        }}
                    />
                </Col>

                {/* 2Select */}
                <Col lg={condition.select1 === "BONUS" ? 8 : condition.arrayKpisSi.includes("SPECIAL") ? 4 : 6} md={12} sm={24}>
                    <Label>KPI</Label>
                    <ConditionalItem
                        items={condition.select1 ? [...(condition.select1 === "PENALIZER" ? [{ key: "ABSENCES", value: "Ausencias" }] : (condition.select1 === "COLLECTIVE" || condition.select1 === "INDIVIDUAL") ? [{ key: "SPECIAL", value: "Por nº de kpis" }] : []), ...kpiList.filter(x => x.type === condition.select1).map(x => ({ key: x.kid, value: x.name }))] : []}
                        mode={"multiple"}
                        value={condition.arrayKpisSi}
                        disabled={locked || condition.select1 === null}
                        handleChange={(value) => {
                            console.log({ value })
                            setConditionals(
                                conditionals.map((cnd, j) => {
                                    if (i !== j) { return cnd }
                                    else {
                                        return ({
                                            ...cnd,
                                            arrayKpisSi: value.includes("SPECIAL") ? ["SPECIAL"] : value,
                                            arrayConditionsSi: null,
                                            intSi: 0,
                                            select5: null,
                                            arrayKpisEntonces: [],
                                            intEntonces: 0
                                        })
                                    }
                                })
                            )
                        }}
                    />
                </Col>

                {
                    condition.arrayKpisSi && condition.arrayKpisSi.includes("SPECIAL") && <Col lg={5} md={12} sm={24}>
                        <Label>Nº de KPIs</Label>
                        <Input
                            placeholder="Nº KPIs"
                            style={{ flex: 1, marginRight: "1em" }}
                            value={condition.specialInt}
                            onChange={event => {
                                setConditionals(
                                    conditionals.map((cnd, j) => {
                                        if (i !== j) { return cnd }
                                        else {
                                            return ({
                                                ...cnd,
                                                specialInt: event.target.value
                                            })
                                        }
                                    })
                                )
                            }}
                        />
                    </Col>
                }
                {/* 3Select */}
                <Col lg={condition.select1 === "BONUS" ? 8 : condition.arrayKpisSi.includes("SPECIAL") ? 5 : 6} md={12} sm={24}>
                    <Label>Condición</Label>
                    <ConditionalItem
                        items={condition.select1 ? itemsThirdSelect[condition.select1] : []}
                        mode={null}
                        value={condition.arrayConditionsSi}
                        disabled={locked || condition.arrayKpisSi.length === 0}
                        handleChange={(value) => {
                            setConditionals(
                                conditionals.map((cnd, j) => {
                                    if (i !== j) { return cnd }
                                    else {
                                        return ({
                                            ...cnd,
                                            arrayConditionsSi: value,
                                            intSi: condition.select1 === "BONUS" ? 1 : 0,
                                            select5: null,
                                            arrayKpisEntonces: [],
                                            intEntonces: 0
                                        })
                                    }
                                })
                            )
                        }}
                    />
                </Col>

                {/* 4Int */}
                <Col lg={6} md={12} sm={24}>
                    {
                        condition.select1 !== "BONUS" && [
                            <Label key="etiq-penalizer">{condition.select1 === "PENALIZER" ? "Unidades" : "Porcentaje de consecución"}</Label>,
                            <Input
                                key="input-penalizer"
                                disabled={locked || condition.arrayConditionsSi === null}
                                value={condition.intSi || undefined}
                                suffix={condition.select1 === "PENALIZER" ? "" : "%"}
                                onChange={event => {
                                    setConditionals(
                                        conditionals.map((cnd, j) => {
                                            if (i !== j) { return cnd }
                                            else {
                                                return ({
                                                    ...cnd,
                                                    intSi: event.target.value,
                                                    select5: null,
                                                    arrayKpisEntonces: [],
                                                    intEntonces: 0
                                                })
                                            }
                                        })
                                    )
                                }}
                            />
                        ]}
                </Col>
            </Row>

            <br />
            <Title>ENTONCES</Title>

            {/* 5Select */}
            <Row gutter={[12, 12]}>
                <Col md={8} sm={24}>
                    <Label>Tipo de KPI</Label>
                    <ConditionalItem
                        items={itemsFifthSelect}
                        value={condition.select5 || undefined}
                        disabled={locked || condition.intSi === 0}
                        handleChange={(value) => {
                            setConditionals(
                                conditionals.map((cnd, j) => {
                                    if (i !== j) { return cnd }
                                    else {
                                        return ({
                                            ...cnd,
                                            select5: value,
                                            arrayKpisEntonces: value === "RVI" ? [value] : [],
                                            intEntonces: 0
                                        })
                                    }
                                })
                            )
                        }}
                    />
                </Col>

                {/* 6Select */}
                <Col md={8} sm={24}>
                    <Label>KPI</Label>
                    <ConditionalItem
                        items={condition.select5 && condition.select5 !== "RVI" ? kpiList.filter(x => x.type === condition.select5).map(x => ({ key: x.kid, value: x.name })) : []}
                        mode={"multiple"}
                        value={condition.arrayKpisEntonces}
                        disabled={locked || condition.select5 === null || condition.select5 === "RVI"}
                        handleChange={(value) => {
                            setConditionals(
                                conditionals.map((cnd, j) => {
                                    if (i !== j) { return cnd }
                                    else {
                                        return ({
                                            ...cnd,
                                            arrayKpisEntonces: value,
                                            intEntonces: 0
                                        })
                                    }
                                })
                            )
                        }}

                    />
                </Col>
                <Col lg={8} sm={24}>
                    <Label>Variación</Label>
                    <RowRow>
                        <Input
                            placeholder="Basic usage"
                            style={{ flex: 1, marginRight: "1em" }}
                            disabled={locked || condition.arrayKpisEntonces.length === 0}
                            value={condition.intEntonces}
                            onChange={event => {
                                setConditionals(
                                    conditionals.map((cnd, j) => {
                                        if (i !== j) { return cnd }
                                        else {
                                            return ({
                                                ...cnd,
                                                intEntonces: event.target.value
                                            })
                                        }
                                    })
                                )
                            }}
                        />
                        <Select value={condition.intEntoncesMode ? condition.intEntoncesMode : "%"} items={[{ key: "%", value: "%" }, { key: "€", value: "€" }]} handleChange={(value) => {
                            setConditionals(
                                conditionals.map((cnd, j) => {
                                    if (i !== j) { return cnd }
                                    else {
                                        return ({
                                            ...cnd,
                                            intEntoncesMode: value,
                                        })
                                    }
                                })
                            )
                        }} mode={null} disabled={locked || condition.arrayKpisEntonces.length === 0} width="100%" />
                    </RowRow>
                </Col>
            </Row>
            <br />
            <PrivateComponent allowed={["admin"]}>
                <div style={{ textAlign: "center" }}>
                    <Button onClick={() => removeCondition(i)} type="red">Eliminar condición</Button>
                </div>
            </PrivateComponent>
        </Card>
    )
}

const Label = styled.label`
    font-weight: bold;
    line-height: 30px;
    color: #777;
    `
const Title = styled.h2`
    font-weight: bold;
    margin-bottom: 0;
    color: rgba(80,150,127,1);
`
const RowRow = styled.div`
    display: flex;
    flex-direction: row;
`

export default SingleCondition