import React from 'react'
import styled from 'styled-components'

const ImageCard = ({ image, round }) => {
    const placeholder = "http://cdn.pccomponentes.com/img/pccom/cabecera-pccom-v2.jpg"
    const src = image || placeholder
    return (
        <ImageWrapper>
            <Image src={src} round={round}></Image>
        </ImageWrapper>
    )
}

const ImageWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
`

const Image = styled.img`
    width: ${({ round }) => round ? "90px" : "100%"};
    height: ${({ round }) => round ? "90px" : "100%"};
    border-radius: ${({ round }) => round ? "45px" : "0px"};
    object-fit: cover;
`

export default ImageCard