import React, { useContext, useEffect } from 'react';
import { Row, Col } from 'antd';
import { BoxListGoals, BoxListGoalsGlobal } from '../../component';
import { withRouter } from 'react-router-dom'
import styled from 'styled-components';
import _, { get, uniqBy } from 'lodash';
import { useQuery } from '@apollo/react-hooks';
import { LoadingContext } from '../../context';
import { GET_MODEL } from '../../graphql/queries';
import { Container } from '../../libraryComponent';
import { kidBase, kidTeoricos } from '../../utils/constantes';

const Model = ({ match }) => {
    const { setLoading } = useContext(LoadingContext);
    const model = match.params.id;
    const { data } = useQuery(GET_MODEL, {
        variables: {
            model
        },
        onCompleted: () => setLoading(false)
    })

    const kpisIndividual = uniqBy(get(data, "model.kpis", []).filter(x => x.type === "INDIVIDUAL"), "kid")
    const kpisCollective = uniqBy(get(data, "model.kpis", []).filter(x => x.type === "COLLECTIVE"), "kid")
    const kpisPenalizer = uniqBy(get(data, "model.kpis", []).filter(x => x.type === "PENALIZER" && x.kid !== kidBase && x.kid !== kidTeoricos), "kid")
    const kpisBonus = uniqBy(get(data, "model.kpis", []).filter(x => x.type === "BONUS"), "kid")
    const globalUser = uniqBy(get(data, "model.kpis", []).filter(x => x.type === "PENALIZER" && x.kid === kidBase || x.kid === kidTeoricos), "kid")

    useEffect(() => {
        setLoading(true)
    }, [])

    return (
        <Container fluid>
            <Row style={{ display: "flex", justifyContent: "center" }}>
                <Col>
                    <Circulo>
                        <Title>
                            <span>{get(data, "model.name", "")}</span>
                        </Title>
                    </Circulo>
                </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
                {
                    console.log(process.env.REACT_APP_ARROWS)
                }
                <Arrows src={process.env.REACT_APP_ARROWS} alt="Arrows" />
            </Row>
            <Row>
                <Col xs={24} md={12} lg={6} >
                    <BoxListGoals data={{ title: "Objetivos Individuales", data: kpisIndividual }} />
                </Col>
                <Col xs={24} md={12} lg={6} >
                    <BoxListGoals data={{ title: "Objetivos Colectivos", data: kpisCollective }} />
                </Col>
                <Col xs={24} md={12} lg={6} >
                    <BoxListGoals data={{ title: "Bonus", data: kpisBonus }} />
                </Col>
                <Col xs={24} md={12} lg={6} >
                    <BoxListGoals data={{ title: "Penalizadores", data: kpisPenalizer }} />
                    <BoxListGoalsGlobal data={{ title: "Datos Globales", data: globalUser }}/>
                </Col>
            </Row>
        </Container>
    );
}

const Circulo = styled.div`
    background-color: #666;
    height: 138px;
    width: 153px;
    border-radius: 50%;
    box-shadow: 0 0 10px 0 #a8a8a8;
    display: flex;
    justify-content: center;
    align-items: center;
    span{
        color: white;
        font-weight: bold;
        font-size: 1.3em;
    }
`

const Arrows = styled.img`
    width: 75%;
    margin: 0 0.5em 0.5em 0.5em;
    object-fit: contain;
    object-position: 0%;
`

const Title = styled.div`
    color: black;
    font-size: large;
`


export default withRouter(Model)