import React, { useState } from 'react'
import { get } from 'lodash'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, allowed = [], ...rest }) => {
    const jwt = localStorage.getItem("jwt")
    let user = undefined
    try {
        user = JSON.parse(localStorage.getItem("user"))
    } catch (err) { }
    const role = get(user, "role.type")

    return get(user, "changePass", true) ? <Route
        {...rest}
        render={props => (
            !jwt ? <Redirect to={{ pathname: "/auth/logout", state: { from: props.location } }} /> : (
                !allowed.includes(role) ? <Redirect to={{ pathname: "/403", state: { from: props.location } }} /> : <Component {...props} />
            ))}
    /> : <Redirect
            to={{
                pathname: "ChangePass",
            }}
        />

}

export default PrivateRoute