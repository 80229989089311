export const gradients = {
    green: "linear-gradient(135deg, rgba(131,222,176,1) 0%, rgba(13,109,97,1) 100%);",
    yellow: "linear-gradient(135deg, rgba(249,190,80,1) 0%, rgba(248,147,53,1) 100%);",
    red: "linear-gradient(135deg, rgba(228,66,82,1) 0%, rgba(175,19,40,1) 100%);",
    blue: "linear-gradient(135deg, rgba(23,100,178,1) 0%, rgba(8,195,222,1) 100%);",
    grey: "linear-gradient(135deg, rgba(134,134,134,1) 0%, rgba(126,126,126,1) 100%);",
    white: "white",
    newgreen: "linear-gradient(135deg, rgba(111,166,62,1) 0%, rgba(172,234,75,1) 100%);",
    newred: "linear-gradient(135deg, rgba(163,34,37,1) 0%, rgba(225,62,81,1) 100%);"
    // 0d6d61
  }