import React, { forwardRef } from 'react'
import { Input as AntdInput } from 'antd'
import styled, { css } from 'styled-components'

const Input = forwardRef(({ labelText, labelPosition = "top", errorText, isError, style, type, ...rest }, ref) => {
    return (
        <StyleWrapper style={style} isError={isError}>
            <InnerWrapper labelPosition={labelPosition}>
                { labelText && <Label htmlFor={labelText} labelPosition={ labelPosition }>{ labelText }</Label> }
                { type === "password" ?
                    <AntdInput.Password ref={ref} {...rest} name={labelText} /> :
                    <AntdInput ref={ref} type={type} {...rest} name={labelText} />
                }
            </InnerWrapper>
            <ErrorWrapper isError={isError} errorText={errorText}>
                { isError && <ErrorText>{ errorText }</ErrorText> }
            </ErrorWrapper>
        </StyleWrapper>
    )
})

const StyleWrapper = styled.div`
    .ant-input, .ant-input-password{
        border-radius: 14px;
        border-color: ${({ isError }) => !isError ? "#d9d9d9" : "rgba(240, 0, 0, 0.4)"};
        &:hover{
            border-color: ${({ isError }) => !isError ? "rgba(19, 177, 91, 0.5)" : "rgba(240, 0, 0, 0.85)"};
        }
        &:focus{
            border-color: ${({ isError }) => !isError ? "#13b15b" : "rgba(240, 0, 0, 0.85)"};
            box-shadow: ${({ isError }) => !isError ? "0 0 0 2px rgba(24, 180, 120, 0.2)" : "0 0 0 2px rgba(120, 24, 24, 0.2)"};
        }
    }
    .ant-input[disabled], .ant-input[disabled]:hover{
        border-color: #d9d9d9 !important;
    }
    .ant-input-affix-wrapper{
        border-radius: 14px;
    }
    .ant-input-affix-wrapper-focused{
        border-color: ${({ isError }) => !isError ? "#13b15b" : "rgba(240, 0, 0, 0.85)"};
        box-shadow: ${({ isError }) => !isError ? "0 0 0 2px rgba(24, 180, 120, 0.2)" : "0 0 0 2px rgba(120, 24, 24, 0.2)"};
    }
    .ant-input-password .ant-input{
        padding-left: 4px !important;
    }`

const InnerWrapper = styled.div`
    ${({ labelPosition }) => labelPosition === "left" && css`
        display: flex;
        align-items: center;
    `}
`

const Label = styled.p`
    ${({ labelPosition }) => labelPosition === "left" && css`margin-right: 1em;`}
    margin-bottom: ${({ labelPosition }) => labelPosition === "top" ? "8px" : "1px"};
    font-size: 1.1em;
`

const ErrorWrapper = styled.div`
    transition: all 200ms ease-in;
    height: ${({ isError, errorText }) => (isError && errorText) ? "1em" : "0px" };
    margin-top: ${({ isError, errorText }) => (isError && errorText) ? "3px" : "0px" };
    opacity: ${({ isError, errorText }) => (isError && errorText) ? "1" : "0" };
`

const ErrorText = styled.span`
    color: #ec7474;
`

export default Input

