import React, { useState, useEffect, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { useMutation } from '@apollo/react-hooks';
import { LOGIN } from '../../graphql/mutations'
import { Container, Input, Button } from '../../libraryComponent'
import { get } from 'lodash'
import styled from 'styled-components'
import { message } from 'antd';

const Login = ({ history }) => {
    const [identifier, setIdentifier] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const userInput = useRef(null)
    const pwInput = useRef(null)
    const [login, { loading }] = useMutation(LOGIN, {
        onCompleted: async data => { 

            if(data && data.login && data.login.jwt){
                setError(false)
                const me = await ( await fetch(process.env.REACT_APP_API_URL + "/users/" + data.login.user.id, {
                    headers: {
                        "Accept": "application/json",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + data.login.jwt
                    }
                })).json()
                localStorage.setItem("jwt", data.login.jwt);
                localStorage.setItem("user", JSON.stringify(me));
                
                if(me.role.type === "authenticated"){
                    history.push("/resultlist")
                }
                else if(me.role.type === "manager"){
                    history.push("/hierarchy")
                }
                else if(me.role.type === "admin"){
                    history.push("/")
                }
                else{
                    message.error("Rol incorrecto")
                    history.push("/auth/logout")
                }
            }
            else{
                console.log("Error raro: " + JSON.stringify(data))
            }
        },
        onError: err => {
            const msg = get(err, "networkError.result.errors[0].message")
            const errorMsg = msg === "Auth.form.error.blocked" ? "El usuario está bloqueado. Contacte con el administrador" : (
                             msg === "Auth.form.error.password.provide" ? "No has introducido contraseña" : (
                             msg === "Auth.form.error.email.provide" ? "No has introducido nombre de usuario" : (
                             msg === "Auth.form.error.invalid" ? "El usuario o la contraseña son inválidos" : "Ha ocurrido un problema durante el login. Inténtelo más tarde."
            )))
            window.localStorage.clear()
            setError(true)
            setErrorText(errorMsg)
        }
    })

    const handleLogin = ({ identifier, password }) => {
        login({ variables: { input: { identifier, password  }} })
    }

    // useEffect(() => {
    //     const fn = e => {
    //         if(e.key === "Enter"){
    //             handleLogin({ 
    //                 identifier: get(userInput, "current.props.value", ""), 
    //                 password: get(pwInput, "current.props.value", "") 
    //             })
    //         }
    //     }
    //     window.addEventListener("keydown", e => { fn(e) } )
    //     return window.removeEventListener("keydown", e => { fn(e) } )
    // }, [])


    return (
        <Container style={{ marginTop: "1em" }}>
            <h1>Accede a RVI</h1>
            <div style={{ marginBottom: "1em" }}>
                <Input ref={userInput} labelText="Usuario" value={identifier} onChange={e => setIdentifier(e.target.value)} isError={error}></Input>
                <Input ref={pwInput} labelText="Contraseña" type="password" value={password} onChange={e => setPassword(e.target.value)} isError={error} errorText={errorText}></Input>
            </div>
            <Wrapper>
                <Button type="green" loading={loading} onClick={() => handleLogin({ identifier, password })}>Acceder</Button>
                <Forgot><Link to="/auth/forgot-password">Olvidé mi contraseña</Link></Forgot>
            </Wrapper>
        </Container>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;
`
const Forgot = styled.div`
    margin-top: 1em;
    a{
        color: #5a5a5a;
        font-weight: bold;
        cursor: pointer;
    }
    a:hover{
        color: #777;
    }
`

export default withRouter(Login)