import React, { useEffect, useContext, useState } from 'react';
import { withRouter } from 'react-router-dom'
import { useLazyQuery } from "@apollo/react-hooks";
import { TeamCard } from "../../component";
import { Container } from "../../libraryComponent";
import { LoadingContext, UploadSelectorContext, FinalContext } from "../../context/";
import { GET_HIERARCHY, GET_MASTER_TEAM } from '../../graphql/queries';
import { get } from 'lodash'
import { Row, Col } from 'antd'

const Hierarchy = ({ history }) => {
    const user = JSON.parse(localStorage.getItem("user"))
    const isAdmin = user && user.role && user.role.type === "admin"
    const { setLoading } = useContext(LoadingContext);
    const { value } = useContext(UploadSelectorContext);
    const { value: valueFinal } = useContext(FinalContext);
    const [loadMasterTeam, { data: dataMaster }] = useLazyQuery(GET_MASTER_TEAM, {
        variables: {
            uploadrvi: value,
            final: valueFinal === "PROV" ? false : true
        }
    })
    const [myTeams, setMyTeams] = useState([])
    useEffect(() => {
        if(dataMaster){
            const masterId = get(dataMaster, "teams[0].id")
            if(masterId){ history.push("/team/" + masterId) }
        }
    }, [dataMaster])

    useEffect(() => {
        if(value && valueFinal){
            if(isAdmin){
                loadMasterTeam()
            }
            else{
                const myTeams = get(user, "managerOf", []).filter(mof => mof.uploadrvi === value && mof.final === (valueFinal === "FINAL"))
                if(myTeams.length === 1){
                    history.push("/team/" + myTeams[0].id)
                }
                else{
                    setMyTeams(myTeams)
                }
            }
        }
    }, [value, valueFinal])

    return (
        <Container>
            <h1>Mis equipos</h1>
            {(myTeams && (
                <Row gutter={[24, 24]}>
                    {myTeams && myTeams.map(t => (
                        <Col xs={24} sm={12} md={8} key={t.id}>
                            <TeamCard team={t} hideManagers />
                        </Col>
                    ))}
                </Row>
            ))}
            {
                myTeams.length === 0 && (
                    <h3>No hay equipos { valueFinal === "FINAL" ? "finales" : "provisionales" } en el mes seleccionado</h3>
                )
            }
        </Container>
    )
}

export default withRouter(Hierarchy)