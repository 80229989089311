import React from 'react'
import styled from 'styled-components'
import { gradients } from '../../utils/gradients'

const Bar = ({ perc, color, ...rest }) => {
    return (
        <Wrapper {...rest}>
            <InnerBar perc={perc} color={color} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    height: 16px;
    background-color: #e8e8e8;
    border-radius: 8px;
    overflow: hidden;
    transition: all 300ms;
`
const InnerBar = styled.div`
    width: ${({ perc }) => perc + "%"};
    height: 100%;
    background: ${({color}) => gradients[color]}
    border-radius: 6px;
    transition: all 300ms;
`

export default Bar