import React from 'react'
import { Bar } from '..'
import styled from 'styled-components'
import { gradients } from '../../utils/gradients'
import { round } from 'lodash'

const CardSingleResult = ({ title, rvi, perc, result, objetive, kpi, weight }) => {
    const color = perc >= 100 ? "newgreen" : "newred"

    return (
        <Wrapper>
            <h4>{title}</h4>
            <div className="bar">
                <Bar perc={perc ? perc : 0} color={color} style={{ width: "100%" }} />
                <span>{perc ? perc.toFixed(2) : 0}%</span>
            </div>
            <LowerWrapper>

                <div>
                    <small>Resultado</small>
                    <span className="result">{round(result, 2)}</span>
                </div>
                <div>
                    <small>Peso</small>
                    <span className="objetive">{weight} %</span>
                </div>
                <div>
                    <small>Objetivo</small>
                    <span className="objetive">{round(objetive, 2)}</span>
                </div>
            </LowerWrapper>
            <RVIBox color={color}>
                <span>+{rvi ? round(rvi, 2) : 0}€</span>
            </RVIBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 10px;
    margin-bottom: 1em;
    h4{
        padding-right: 45px;
    }
    .bar{
        display: flex;
        align-items: center;
        width: 100%;
        & > span {
            padding-left: 10px;
        }
    }
`
const LowerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    flex: 1;
    &>div{
        display: flex;
        flex-direction: column;
    }
    .result{
        font-size: 1.6em;
        font-weight: bold;
    }
    .objetive{
        font-size: 1.1em;
        font-weight: bold;
    }
`

const RVIBox = styled.div`
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    background: ${({ color }) => gradients[color]};
    padding: 5px;
    color: white;
    border-radius: 0 0 0 8px;
`

export default CardSingleResult