import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import LoadingContext from '../../context/LoadingContext'

const Loader = () => {
    const { loading } = useContext(LoadingContext)
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        if(loading){ 
            setTimeout(() => {
                setVisible(true) 
            }, 200)
        }
        else if(loading === false){
            setTimeout(() => {
                setVisible(false)
            }, 200)
        }
    }, [loading])

    return (
        visible ? 
            <LoaderWrapper isLoading={loading} >
                <ImageAndProgress>
                <img src={process.env.REACT_APP_API_URL + "/" + process.env.REACT_APP_LOADER} />
                { typeof loading === "number" &&
                    <OuterProgress>
                        <InnerProgress progress={loading}/>
                    </OuterProgress>
                }
                </ImageAndProgress>
                <LoaderBackground />
            </LoaderWrapper> : <div />
    )
}

const LoaderWrapper = styled.div`
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1000000000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 300ms;
    opacity: ${({ isLoading }) => isLoading ? 0.9 : 0};
`
const ImageAndProgress = styled.div`
    display: relative;
    z-index: 10000000001;
`
const LoaderBackground = styled.div`
    background-color: white;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`
const OuterProgress = styled.div`
    height: 38px;
    border-radius: 20px;
    border: 2px solid rgba(80,150,127,1);
    width: 100%;
    overflow: hidden;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
`
const InnerProgress = styled.div`
    height: calc(100% - 2px);
    border-radius: 16px 4px 4px 16px;
    width: calc(${({ progress }) => progress + "%"} - 2px);
    margin-top: 1px;
    margin-left: 1px;
    transition: all 300ms;
    overflow: hidden;
    background: linear-gradient(135deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%);
`

export default Loader