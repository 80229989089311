import React from 'react'
import { withRouter } from 'react-router-dom'
import { Modal } from 'antd'
import { Button } from '../../libraryComponent'
import styled from 'styled-components'

const ModalConfirmModel = ({ visible, setVisible, calc, nocalc, modelName, recalcFlag }) => {

    return (
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            closable={true}
            // confirmLoading={loading}
            footer={<WrapperButton>
                <Button type="red" onClick={() => calc()}>{ recalcFlag ? "Si, recalcular RVI" : "Si, comenzar el cálculo" }</Button>
                <Button type="green" onClick={() => nocalc()}>No, guardar solo condiciones</Button>
            </WrapperButton>}
        >
            <Wrapper>
                <p>¿Guardar las condiciones del modelo {modelName} y comenzar el cálculo del RVI para este modelo?</p>
            </Wrapper>
        </Modal>
    )
}


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 20px;
`
const WrapperButton = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    justify-content: space-between;
`
export default ModalConfirmModel