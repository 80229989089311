import React from 'react'
import { Select } from 'antd';
import styled from 'styled-components';

const { Option } = Select;

const SelectRVI = ({ items, onChange, handleChange, mode, value, disabled, width }) => {
    return (
        <Wrapper disabled={disabled}>
            <Select
                mode={mode}
                value={value}
                placeholder="Selecciona una opción"
                style={{ width }}
                disabled={disabled}
                onChange={onChange || handleChange}
            >
                {items.map((item) => {
                    return <Option key={item.key}>{item.label || item.value}</Option>
                })}
            </Select>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .ant-select-selector{
        border-radius: 14px !important;
    }
    .ant-select:hover .ant-select-selector{
        border-color: ${({ disabled }) => disabled ? "#d9d9d9" : "rgba(19, 177, 91, 0.5) !important"};
    }
    .ant-select-focused .ant-select-selector{
        border-color: rgba(19, 177, 91, 0.5) !important;
        box-shadow: 0 0 0 2px rgba(19, 177, 91, 0.2) !important;
    }
`
export default SelectRVI;