import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/react-hooks'
import { Modal, message } from 'antd'
import { MonthPicker } from '../../libraryComponent'
import moment from 'moment'
import styled from 'styled-components'
import { CREATE_UPLOADRVI } from '../../graphql/mutations'
import { GET_UPLOAD_RVIS } from '../../graphql/queries'

const NewUploadRVIModal = ({ visible, setVisible, uploadrvis }) => {
    const [initialDate, setInitialDate] = useState(moment().startOf("month"))
    const [finalDate, setFinalDate] = useState(moment().endOf("month"))
    const [createUploadrvi, { loading }] = useMutation(CREATE_UPLOADRVI, {
        onCompleted: data => { setVisible(false) },
        onError: err => console.log({ err }),
        refetchQueries: [{ query: GET_UPLOAD_RVIS }]
    })

    const handleCreateUploadrvi = () => {
        if( uploadrvis.find(urvi => moment(urvi.date_start).isSame(initialDate, "month"))){
            message.info("Ya existe una subida de archivos creada para el mes seleccionado")
        }
        else{
            const id = JSON.parse(localStorage.getItem("user")).id
            createUploadrvi({ variables: {
                date_start: initialDate.utc().format(),
                date_end: finalDate.utc().format(),
                files: {},
                created_user: id
            }})
        }
    }

    return (
        <Modal
            visible={visible}
            onOk={() => handleCreateUploadrvi()}
            onCancel={() => setVisible(false)}
            closable={false}
            confirmLoading={loading}
        >
            <Wrapper>
                <p>Seleccione el mes de la nueva nueva de archivos que se va a crear</p>
                <MonthPicker value={initialDate} onChange={month => {
                    setInitialDate(month && month.clone().startOf("month"))
                    setFinalDate(month && month.clone().endOf("month"))
                }}/>
            </Wrapper>
        </Modal>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`

export default NewUploadRVIModal