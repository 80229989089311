import React, { useState, useEffect, useRef, useContext } from "react";
import { Link } from 'react-router-dom'
import { Table } from "../../component";
import { CheckCell, TableLabel, Container, Button } from "../../libraryComponent";
import { generateTableFilterProps, generateTableSearchProps } from '../../utils'
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { CopyOutlined } from '@ant-design/icons';
import { GET_MODELS } from "../../graphql/queries";
import { UPDATE_MODEL } from "../../graphql/mutations";
import LoadingContext from "../../context/LoadingContext";
import UploadSelectorContext from "../../context/UploadSelectorContext";
import CopiedConditionsContext from "../../context/CopiedConditionsContext";
import FinalContext from "../../context/FinalContext";
import ModalCalculate from "../../component/ModalCalculate/ModalCalculate";

const ModelList = () => {
    const { setLoading } = useContext(LoadingContext);
    const role = JSON.parse(localStorage.getItem("user")).role.type
    const { value } = useContext(UploadSelectorContext);
    const { value: valueCCC, setValue } = useContext(CopiedConditionsContext);
    const { value: valueFinal } = useContext(FinalContext);
    const [visible, setVisible] = useState(false)
    const [modalInfo, setModalInfo] = useState({ model: "", calculate: true, result_models: [] })
    const [loadModel, { loading, data, refetch }] = useLazyQuery(GET_MODELS, {
        onCompleted: () => setLoading(false),
        variables: { uploadrvi: value, final: valueFinal === "PROV" ? false : true }
    })

    const [updateModel] = useMutation(UPDATE_MODEL, {
        onCompleted: () => {
            setVisible(false)
        },
        refetchQueries: [
            {
                query: GET_MODELS,
                variables: { uploadrvi: value, final: valueFinal === "PROV" ? false : true }
            }
        ]
    });

    const searchInput1 = useRef(null)
    const searchInput2 = useRef(null)
    const columns = [
        {
            title: "Modelo",
            key: "name",
            align: "center",
            ...generateTableSearchProps({
                dataIndex: "name",
                reference: searchInput1
            }),
            render: row => <TableLabel dark>{row.name}</TableLabel>
        },
        {
            title: "Actividad",
            key: "activity",
            ...generateTableSearchProps({
                dataIndex: "activity",
                reference: searchInput2
            }),
            render: row => <TableLabel>{row.activity}</TableLabel>
        },
        {
            title: "Rol",
            key: "role",
            align: "center",
            render: row => <TableLabel>{row.role}</TableLabel>,
            ...generateTableFilterProps({ dataIndex: "role", dataSource: data && data.models })
        },
        {
            title: "Dirección",
            key: "direction",
            align: "center",
            render: row => <TableLabel>{row.direction}</TableLabel>
        },
        {
            title: "Kpis",
            key: "ver_kpis",
            align: "center",
            render: row => row.completed ? <Button onClick={() => { }} ><Link
                to={{
                    pathname: "/model/" + row.id
                }}
            > <TableLabel>Kpis</TableLabel></Link></Button> : <TableLabel>Modelo incompleto</TableLabel>
        },
        {
            title: "Condiciones",
            key: "condiciones",
            align: "center",
            render: row => <Button onClick={() => { }} ><Link
                to={{
                    pathname: "/conditionals",
                    search: "?model=" + row.id
                }}
            >
                <TableLabel>Condiciones</TableLabel></Link></Button>
        },
        {
            title: "Copiar Condiciones",
            key: "copiar_condiciones",
            align: "center",
            render: row => (row.conditions && row.conditions.length > 0) ? <Button onClick={() => {
                setValue({ id: row.id, name: row.name, createdAt: row.createdAt, conditions: row.conditions })
            }} ><TableLabel><CopyOutlined />Copiar Condiciones</TableLabel></Button> : <TableLabel>Nada que copiar</TableLabel>
        }
    ]
    const adminColumns = role === "admin" ? [
        {
            title: "Calcular RVI",
            key: "Calcular_rvi",
            align: "center",
            render: row => row.final ? row.result_models.length <= 0 ? <Button onClick={() => {
                setModalInfo({
                    model: row.name,
                    calculate: true,
                    result_models: row.result_models,
                    id: row.id
                });
                setVisible(true);
            }}><TableLabel>Calcular</TableLabel></Button> : <Button onClick={() => {
                setModalInfo({
                    model: row.name,
                    calculate: false,
                    result_models: row.result_models,
                    id: row.id
                });
                setVisible(true);
            }}><TableLabel>Recalcular</TableLabel></Button> : null
        }
    ] : []

    useEffect(() => {
        if (value) {
            loadModel()
        }
    }, [value])

    useEffect(() => {
        setLoading(loading)
    }, [loading])

    return (
        <Container>
            <h1>Modelos</h1>
            <Table dataSource={data && data.models} columns={[...columns, ...adminColumns]} rowKey={"id"} loading={loading} />
            <ModalCalculate visible={visible} setVisible={visible => setVisible(visible)} modalInfo={modalInfo} updateModel={variables => updateModel(variables)} refetch={refetch} />
        </Container>
    )
};

export default ModelList;