import React, { useEffect, useState, useContext } from 'react'
import { UserCard, ValidationItem, HistoryChart } from '../../component';
import { get, upperFirst, round } from 'lodash';
import { Container, CardGlobalResult, CardSingleResult, CardSimple, Select } from '../../libraryComponent';
import { Row, Col, message, DatePicker } from 'antd';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks';
import { GET_RESULT, GET_HISTORY } from '../../graphql/queries';
import { LoadingContext } from '../../context';
import { currency, kidBase, kidTeoricos } from '../../utils/constantes';
import moment from 'moment';
import { calculatervi } from '../../utils/calculatervi';

const Result = ({ match, history }) => {
    const { setLoading } = useContext(LoadingContext);
    const [dataKpi, setData] = useState(null);
    const [rvi, setRvi] = useState(0)
    const [error, setError] = useState(false)
    const [selector, setSelector] = useState(match.params.id)
    const [selectedMonth, setSelectedMonth] = useState(moment())
    const [showSelector, setShowSelector] = useState(true)
    const [resultModels, setResultModels] = useState([])
    const [validated, setValidated] = useState(false)

    const { data: dataHistory } = useQuery(GET_HISTORY, {
        variables: {
            userid: match.params.userid
        }
    })
    const { data, refetch } = useQuery(GET_RESULT, {
        variables: {
            id: match.params.id,
            userid: match.params.userid
        },
        onCompleted: data => {
            setLoading(false)
            if (!data.model) {
                setError(true)
            }
        }
    })

    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        if (data && data.model) {
            const { kpis, result_models } = data.model;
            const selectRM = get(dataHistory, "resultModels", []).map(rm => ({
                key: rm.model.id,
                value: rm.model.id,
                label: upperFirst(moment(rm.model.uploadrvi.date_end).format("MMMM YYYY"))
            }))
            setResultModels(selectRM)

            if (!result_models[0]) {
                message.error("RVI No calculado.")
            } else {
                if (result_models[0].validated && result_models[0].validated.length > 0) {
                    setValidated(true)
                }
            }

            const individual = kpis.filter(x => x.type === "INDIVIDUAL")
                .map(x => {
                    const condicionado = data.model.result_models[0] && data.model.result_models[0].result && data.model.result_models[0].result.length > 0 && data.model.result_models[0].result.find(z => z.kid === x.kid);
                    if (condicionado) {
                        const amount = x.amount + condicionado.amount_conditioned
                        return { ...x, amount: amount < 0 ? 0 : amount }
                    } else {
                        return x
                    }
                })
            const collective = kpis.filter(x => x.type === "COLLECTIVE").map(x => {
                const condicionado = data.model.result_models[0] && data.model.result_models[0].result && data.model.result_models[0].result.length > 0 && data.model.result_models[0].result.find(z => z.kid === x.kid);
                if (condicionado) {
                    const amount = x.amount + condicionado.amount_conditioned
                    return { ...x, amount: amount < 0 ? 0 : amount }
                } else {
                    return x
                }
            })

            const rviConditionedPre = get(data, "model.result_models[0].result", []).find(z => z.kid === "RVI")
            const rviConditioned = get(rviConditionedPre, "amount_conditioned", 0)
            const amount_conditioned_complete_perc = rviConditioned !== 0 ? rviConditioned.filter(x => x.intEntoncesMode === "%").reduce((c, d) => c + d.intEntonces, 0) : 0;
            const amount_conditioned_complete_fix = rviConditioned !== 0 ? rviConditioned.filter(x => x.intEntoncesMode === "€").reduce((c, d) => c + d.intEntonces, 0) : 0;
            const rviNoConditioned = individual.reduce((a, b) => a + parseFloat(b.amount), 0) + collective.reduce((a, b) => a + parseFloat(b.amount), 0)
            // const newrvi = 
            const rvi = rviNoConditioned + (rviNoConditioned * amount_conditioned_complete_perc / 100) + amount_conditioned_complete_fix;
            setRvi(rvi)

            setData({
                individual,
                collective,
                bonus: kpis.filter(x => x.type === "BONUS"),
                penalizer: kpis.filter(x => x.type === "PENALIZER" && x.kid !== kidBase && x.kid !== kidTeoricos)
            })

            setLoading(false)
        }
    }, [data])

    useEffect(() => {
        if (resultModels.some(rm => rm.value === selector)) {
            setShowSelector(true)
            const a = resultModels.find(r => r.value === selector)
            const whichMonth = moment(a.label)
            setSelectedMonth(whichMonth)
        }
        else { setShowSelector(false) }
    }, [selector, resultModels])

    if (error) {
        return (
            <Container>
                <h2>La URL que has seguido no es válida o su contenido ha sido borrado</h2>
            </Container>
        )
    }
    else {
        return (
            <Container fluid>
                {resultModels.length > 0 && showSelector && (
                    <Row style={{ marginBottom: 12 }}>
                        <DatePicker.MonthPicker
                            value={selectedMonth}
                            onChange={e => {
                                const selectedRM = dataHistory.resultModels.find(rm => moment(get(rm, "model.uploadrvi.date_end")).format("MMMM YYYY") === e.format("MMMM YYYY"))
                                if (selectedRM) {
                                    setSelector(selectedRM.model.id)
                                    setSelectedMonth(selectedRM.model.date_end)
                                    setLoading(true)
                                    history.push("/result/" + selectedRM.model.id + "/" + match.params.userid)
                                }
                            }}
                            monthCellRender={date => {
                                const selectable = resultModels.map(mes => mes.label.toLowerCase()).includes(date.format("MMMM YYYY"))
                                const selected = resultModels.find(r => r.value === selector)
                                const active = selected.label.toLowerCase() === date.format("MMMM YYYY")
                                return (
                                    <SingleMonth active={active} selectable={selectable}>
                                        <span>{upperFirst(date.format("MMMM"))}</span>
                                    </SingleMonth>
                                )
                            }}
                        />
                    </Row>
                )}
                <Row gutter={[18, 18]}>
                    <Col xs={24} sm={12}>
                        <Row>
                            <UserCard
                                name={get(data, "model.kpis[0].user.name", "") + " " + get(data, "model.kpis[0].user.lastname", "")}
                                email={get(data, "model.kpis[0].user.email", "")}
                                team={get(data, "model.kpis[0].user.workerOf[0]", "")}
                                job={get(data, "model.activity", "")}
                                image="https://vietnamhairs.vn/wp-content/uploads/1068x534/suggested-hairstyle-for-girl-in-the-office-3.jpg"
                                style={{ height: 232 }}
                            />
                        </Row>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Row>
                            {data && data.model && data.model.final && (
                                <CardSimple
                                    notSoSimple={true}
                                    title="Retribución variable mensual"
                                    value={dataKpi ? "+" + (rvi < 0 ? 0 : rvi) : 0}
                                    bigvalue={true}
                                    linecolor="green"
                                    rvi={rvi}
                                    linecolor="green"
                                    resultUser={match.params.userid}
                                    result_model={dataKpi && data.model.result_models[0]}
                                    refetch={() => refetch()}
                                    validated={validated}
                                />
                            )}
                        </Row>
                        <Row gutter={[18, 18]}>
                            <Col xs={6}><CardSimple title="Base RVI" value={get(get(data, "model.kpis", []).filter(x => x.kid === kidBase), "[0].achievement", 0)} style={{ marginBottom: 0 }} /></Col>
                            <Col xs={6}><CardSimple title="Días Teoricos" value={get(get(data, "model.kpis", []).filter(x => x.kid === kidTeoricos)[0], "[0].achievement", 0)} style={{ marginBottom: 0 }} /> </Col>
                            <Col xs={6}><CardSimple title="Jornada" value={get(data, "model.uploadrvi.workdays[0].workdayfraction")} style={{ marginBottom: 0 }} /></Col>
                            <Col xs={6}><CardSimple title="Ausencias" value={get(data, "model.uploadrvi.workdays[0].absences")} style={{ marginBottom: 0 }} /> </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[18, 18]}>
                    <Col md={24}>
                        <Row gutter={[18, 18]}>
                            <Col md={6} sm={12} xs={24}>
                                <Row style={{ marginBottom: 12 }}>
                                    <CardGlobalResult
                                        title={<strong>Individuales</strong>}
                                        result={get(dataKpi, "individual[0].resultkpi") ? "+" + round(dataKpi.individual.reduce((a, b) => a + parseFloat(b.amount), 0), 2) + currency : "" + 0 + currency}
                                    />
                                </Row>
                                {dataKpi && dataKpi.individual.map(kpi => (
                                    <Row key={kpi.id}>
                                        <CardSingleResult
                                            refetch={() => refetch()}
                                            kpi={kpi}
                                            result_model={data.model.result_models[0]}
                                            user={match.params.userid}
                                            title={kpi.name}
                                            rvi={kpi.amount}
                                            perc={kpi.achievement}
                                            result={kpi.resultkpi}
                                            objetive={kpi.objetive}
                                            weight={kpi.weight}
                                        />
                                    </Row>
                                ))}
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                                <Row style={{ marginBottom: 12 }}>
                                    <CardGlobalResult
                                        title={<strong>Colectivos</strong>}
                                        result={get(dataKpi, "individual[0].resultkpi") ? "+" + round(dataKpi.collective.reduce((a, b) => a + parseFloat(b.amount), 0), 2) + currency : "" + 0 + currency}
                                    />
                                </Row>
                                {/* map de colectivos */}
                                {dataKpi && dataKpi.collective.map(kpi => (
                                    <Row key={kpi.id}>
                                        <CardSingleResult
                                            refetch={() => refetch()}
                                            kpi={kpi}
                                            result_model={data.model.result_models[0]}
                                            user={match.params.userid}
                                            title={kpi.name}
                                            rvi={kpi.amount}
                                            perc={kpi.achievement}
                                            result={kpi.resultkpi}
                                            objetive={kpi.objetive}
                                            weight={kpi.weight}
                                        />
                                    </Row>
                                ))}
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                                <Row style={{ marginBottom: 12 }}>
                                    <CardGlobalResult title={<strong>Bonus</strong>} line="green" slim />
                                </Row>
                                {/* map de bonus */}
                                {dataKpi && dataKpi.bonus.map(kpi => (
                                    <Row key={kpi.id}>
                                        <CardSimple title={kpi.name} value={kpi.achievement} bonus={true} />
                                    </Row>
                                ))}
                            </Col>
                            <Col md={6} sm={12} xs={24}>
                                <Row style={{ marginBottom: 12 }}>
                                    <CardGlobalResult title={<strong>Penalizadores</strong>} line="red" slim />
                                </Row>
                                {/* map de penalizadores */}
                                {dataKpi && dataKpi.penalizer.map(kpi => (
                                    <Row key={kpi.id}>
                                        <CardSimple title={kpi.name} value={kpi.achievement} bonus={false} notSoSimple={kpi.name === "Base RVI"} />
                                    </Row>
                                ))}
                            </Col>
                        </Row>

                    </Col>
                </Row>
                {dataHistory && (
                    <>
                        <Row gutter={[18, 18]} style={{ justifyContent: "center" }}>
                            <Col xs={18}><h2>Histórico mensual</h2></Col>
                        </Row>
                        <Row gutter={[18, 18]} style={{ justifyContent: "center" }}>
                            <Col md={18} sm={24} xs={24}><HistoryChart data={dataHistory.resultModels.map(x => ({ x: upperFirst(moment(x.model.uploadrvi.date_end).format("MMMM YYYY")), y: calculatervi(x) }))} /></Col>
                        </Row>
                        <Row gutter={[18, 18]} style={{ justifyContent: "center" }}>
                            <Col md={12} sm={18} xs={24}><CardSimple
                                notSoSimple={true}
                                title="Retribución histórica total"
                                value={dataHistory.resultModels.reduce((a, b) => a + calculatervi(b), 0)}
                                bigvalue={true}
                                linecolor="green"
                            /></Col>
                        </Row>
                    </>
                )}
            </Container >
        )
    }
}

const Red = styled.div`
    background-color: red;
    width: 100%;
    height: 100px;
`
const SingleMonth = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    background-color: ${({ active, selectable }) => {
        if (active) { return "grey" }
        else if (selectable) { return "gainsboro" }
    }};
    color: ${({ active, selectable }) => {
        if (active) { return "white" }
        else if (selectable) { return "black" }
        else { return "gainsboro" }
    }};
    border-radius: 4px;
    height: 100%;
    transition: all 300ms;
    &:first-child{
        margin-right: 4px;
    }
    &:nth-child(2){
        margin-left: 4px;
        margin-right: 4px;
    }
    &:last-child{
        margin-left: 4px;
    }
    &:hover{
        opacity: ${({ active }) => active ? 0.7 : 1};
    }
`
export default withRouter(Result)