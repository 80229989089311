import XLSX from 'xlsx'
import moment from 'moment'
import { message } from 'antd';
import { uniqBy, groupBy, kebabCase, get, capitalize, partition, chunk } from 'lodash'

const hadouken = async (file, record, hooks, dataFetched, final) => {
    await hooks.setLoading(0.1)
    return new Promise((resolve, reject) => {

        let fileF = file, read = new FileReader();
        read.readAsBinaryString(fileF)
        read.onloadend = async () => {
            try {
                var oFile = XLSX.read(read.result, { type: 'binary', password: "1234" })
                const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], { 
                    cellNF: true, 
                    raw: false,
                }).filter(row => row["Cod Empleado"])

                if(!dataFetched.roles || !dataFetched.users){
                    throw new Error("El sistema no ha terminado de cargar los datos necesarios para la subida de jerarquía. Espera unos instantes y vuelve a intentarlo")
                }
                
                const roles = dataFetched.roles
                const previousUsers = dataFetched.users.filter(pu => pu.role.type !== "admin")

                const requiredCols = [
                    "Año",
                    "Mes",
                    "Cod Empleado",
                    "Apellidos",
                    "Nombre",
                    "Correo Electrónico",
                    "CODUOCD",
                    "Comité Dirección",
                    "CODUOUD",
                    "Unidad Dirección",
                    "CODUOUDIV",
                    "Unidad División",
                    "CODUOUDPT",
                    "Unidad Departamento",
                    "CODUOUAREA",
                    "Unidad Área",
                    "CODUOUSAREA",
                    "Unidad Subárea",
                    "Posición",
                    "Es Manager",
                ]

                let colErrors = requiredCols.map(req => {
                    if(!data[0][req]){ return req }
                }).filter(e => e)

                if(!data.some(row => row["EAT"])){ colErrors.push("EAT")}
                if(!data.some(row => row["Días Ausencias"])){ colErrors.push("Días Ausencias")}

                if(colErrors.length > 0){
                    console.log({ colErrors })
                    throw new Error(`El archivo es incorrecto o no tiene columna${ colErrors.length > 1 ? "s" : ""} ${colErrors.reduce((acc, name, i, arr) => {
                        const separator = i === 0 ? "" : (i + 1 === arr.length ? " y " : ", ")
                        return acc + separator + name
                    }, "")}`)
                }

                else if(!moment().month(parseInt(data[0]["Mes"]) - 1)
                            .year(parseInt(data[0]["Año"]))
                            .isBetween(moment(record.date_start), moment(record.date_end))
                ){ throw new Error("El archivo no se corresponde con el mes al que se está intentando añadir") }



                const teams = uniqBy(data, "CODUOUSAREA").map((team, i) => {

                    if(i===0 && Object.keys(team).some(k => k.includes("Ã"))){
                        throw new Error("Codificación de caracteres incompatible o incorrecta")
                    }

                    const ccd  = team["CODUOCD"] && team["CODUOCD"].slice(3)
                    const cdir = team["CODUOUD"] && team["CODUOUD"].slice(3)
                    const cdiv = team["CODUOUDIV"] && team["CODUOUDIV"].slice(3)
                    const cdep = team["CODUOUDPT"] && team["CODUOUDPT"].slice(3)
                    const car  = team["CODUOUAREA"] && team["CODUOUAREA"].slice(3)
                    const csub = team["CODUOUSAREA"] && team["CODUOUSAREA"].slice(3)
                    const dcd  = team["Comité Dirección"]
                    const ddir = team["Unidad Dirección"]
                    const ddiv = team["Unidad División"]
                    const ddep = team["Unidad Departamento"]
                    const dar  = team["Unidad Área"]
                    const dsub = team["Unidad Subárea"]
                    
                    const idTeam = (ccd === cdir && ccd === cdiv && ccd === cdep && ccd === car && ccd === csub) ? ccd  : (
                        (cdir === cdiv && cdir === cdep && cdir === car && cdir === csub) ? cdir : (
                        (cdiv === cdep && cdiv === car && cdiv === csub) ? cdiv : (
                        (cdep === car && cdep === csub) ? cdep : (
                        (car  === csub) ? car  : csub 
                    ))))

                    // El codigoPadre se usará despues para definir las jerarquías, es el código del equipo inmediato superior
                    const idFather = (ccd === cdir && ccd === cdiv && ccd === cdep && ccd === car && ccd === csub) ? "qwe"  : (
                        (cdir === cdiv && cdir === cdep && cdir === car && cdir === csub) ? ccd : (
                        (cdiv === cdep && cdiv === car && cdiv === csub) ? cdir : (
                        (cdep === car && cdep === csub) ? cdiv : (
                        (car  === csub) ? cdep  : car
                    ))))

                    const name = (ccd === cdir && ccd === cdiv && ccd === cdep && ccd === car && ccd === csub) ? dcd  : (
                        (cdir === cdiv && cdir === cdep && cdir === car && cdir === csub) ? ddir : (
                        (cdiv === cdep && cdiv === car && cdiv === csub) ? ddiv : (
                        (cdep === car && cdep === csub) ? ddep : (
                        (car  === csub) ? dar  : dsub 
                    ))))

                    const level = (ccd === cdir && ccd === cdiv && ccd === cdep && ccd === car && ccd === csub) ? 0  : (
                        (cdir === cdiv) ? 1 : (
                        (cdiv === cdep) ? 2 : (
                        (cdep === car ) ? 3 : (
                        (car  === csub) ? 4 : 5
                    ))))

                    return {
                        originalRow: team,
                        name,
                        idTeam,
                        directFather: idFather,
                        idFather: [idFather], 
                        level
                    }
                }).filter(t => t.idTeam)

                let teamsPerHierarchyLevel = Object.values(groupBy(teams, "level"))
                teamsPerHierarchyLevel.forEach((level, i) => {
                    if(i > 1){
                        level.forEach((t, j) => {
                            const directFather = teams.find(tf => t.directFather === tf.idTeam)
                            teamsPerHierarchyLevel[i][j].idFather.push(...directFather.idFather)
                        })
                    }
                })

                let uploadedTeams = []
                for await (let level of teamsPerHierarchyLevel){
                    const levelProms = level.map(async team => {
                        const fathers = team.idFather.map(idf => {
                            const fullTeam = uploadedTeams.find(t => t.teamID === idf)
                            return fullTeam ? fullTeam.id : null
                        }).filter(e => e)
                        const variables = {
                            name: team.name,
                            teamID: team.idTeam,
                            level: team.level,
                            slug: kebabCase(team.name),
                            fathers,
                            final,
                            uploadrvi: record.id,
                        }
                        return hooks.createTeam[0]({ variables })
                    })
                    const levelRespPre = await Promise.all(levelProms)
                    const levelResp = levelRespPre.map(r => get(r, "data.createTeam.team"))
                    uploadedTeams = [
                        ...uploadedTeams,
                        ...levelResp
                    ]
                }
                hooks.setLoading(20)

                console.log({ uploadedTeams })

                //EQUIPOS CARGADOS!!


                const usersUIDArr = data.map(u => u["Cod Empleado"].toLowerCase())
                const previousUsersUIDArr = previousUsers.map(pu => pu.UID.toLowerCase())
                const usersToBlock = previousUsers.filter(pu => !usersUIDArr.includes(pu.UID.toLowerCase()))
                const users = data.filter(row => !usersToBlock.map(utb => utb.id).includes(row.id)).map(uraw => {

                    const previousUser = previousUsers.find(pu => pu.UID === uraw["Cod Empleado"])
                    console.log({ previousUser })

                    const ccd  = uraw["CODUOCD"] && uraw["CODUOCD"].slice(3)
                    const cdir = uraw["CODUOUD"] && uraw["CODUOUD"].slice(3)
                    const cdiv = uraw["CODUOUDIV"] && uraw["CODUOUDIV"].slice(3)
                    const cdep = uraw["CODUOUDPT"] && uraw["CODUOUDPT"].slice(3)
                    const car  = uraw["CODUOUAREA"] && uraw["CODUOUAREA"].slice(3)
                    const csub = uraw["CODUOUSAREA"] && uraw["CODUOUSAREA"].slice(3)
                        
                    const idTeam = (ccd === cdir && ccd === cdiv && ccd === cdep && ccd === car && ccd === csub) ? ccd  : (
                        (cdir === cdiv && cdir === cdep && cdir === car && cdir === csub) ? cdir : (
                        (cdiv === cdep && cdiv === car && cdiv === csub) ? cdiv : (
                        (cdep === car && cdep === csub) ? cdep : (
                        (car  === csub) ? car  : csub 
                    ))))

                    const team = uploadedTeams.find(t => t.teamID === idTeam)
                    if(!team){
                        console.log({ uraw })
                    }  
                    
                    const workerOf = [...(get(previousUser, "workerOf", []).map(wof => wof.id)), ...(team ? [team.id] : [])]
                    const manager = ["sí", "si"].includes(uraw["Es Manager"].toLowerCase())
                    const managerOf = [...(get(previousUser, "managerOf", []).map(mof => mof.id)), ...((team && manager) ? [team.id] : [])]
                    const role = manager ? roles.find(r => r.type === "manager").id : roles.find(r => r.type === "authenticated").id
                    const email = uraw["Correo Electrónico"].toLowerCase().trim()
                    const UID = uraw["Cod Empleado"].toLowerCase().trim()
                    const uploadrvis = [...(get(previousUser, "uploadrvis", []).map(urvi => urvi.id)), ...(record ? [record.id] : [])]

                    console.log({
                        workerOf,
                        manager,
                        managerOf,
                        role,
                        email,
                        UID,
                        uploadrvis
                    })

                    const fieldsForNewUsers = !previousUser ? {
                        username: UID,
                        email,
                        password: "cetelem",
                        confirmed: false,
                        name: uraw["Nombre"].split(" ").map(chk => capitalize(chk)).reduce((a, b) => a + (a ? " " : "") + b, ""),
                        lastname: uraw["Apellidos"].split(" ").map(chk => capitalize(chk)).reduce((a, b) => a + (a ? " " : "") + b, "")
                    } : {}
                    const fieldsForUpdatedUsers = previousUser ? {
                        id: previousUser.id,
                        blocked: false
                    } : {}

                    return ({
                        ...fieldsForNewUsers,
                        ...fieldsForUpdatedUsers,
                        UID,
                        role,
                        uploadrvis,
                        managerOf,
                        workerOf,
                        absences: uraw["Días Ausencias"],
                        workdayfraction: uraw["EAT"]
                        // e berDays: uraw["Días trabajados"]
                    })
                })

                // console.log({ users })

                const [usersToUpdate, usersToCreate] = partition(users, u => previousUsersUIDArr.includes(u.UID.toLowerCase()))

                console.log({ usersToBlock, usersToUpdate, usersToCreate })
                const totalCount = usersToBlock.length + 2 * usersToUpdate.length + 2 * usersToCreate.length
                
                let createdUsers = []
                let updatedUsers = []
                let blockedUsers = []
                let usersWithWorkday = []

                const newUserChunks = chunk(usersToCreate, 50)
                for await (const chunk of newUserChunks){
                    const chunkPromises = chunk.map(async newUser => hooks.createUser[0]({ variables: newUser }))
                    const chunkResp = await Promise.all(chunkPromises)
                    createdUsers.push(...chunkResp.map(it => get(it, "data.createUser.user", {})))
                    hooks.setLoading(20 + ((createdUsers.length + updatedUsers.length + blockedUsers.length + usersWithWorkday.length)/totalCount)*80)
                }
                console.log({ createdUsers })
                
                const updateUserChunks = chunk(usersToUpdate, 50)
                for await (const chunk of updateUserChunks){
                    const chunkPromises = chunk.map(async updatedUser => {
                        delete updatedUser.UID
                        updatedUser.blocked = false
                        return hooks.updateUser[0]({ variables: updatedUser })
                    })
                    const chunkResp = await Promise.all(chunkPromises)
                    updatedUsers.push(...chunkResp.map(it => get(it, "data.updateUser.user", {})))
                    hooks.setLoading(20 + ((createdUsers.length + updatedUsers.length + blockedUsers.length + usersWithWorkday.length)/totalCount)*80)
                }
                console.log({ updatedUsers })

                const blockUserChunks = chunk(usersToBlock, 50)
                for await (const chunk of blockUserChunks){
                    const chunkPromises = chunk.map(async blockUser => {
                        return hooks.updateUser[0]({ variables: { id: blockUser.id, blocked: true } })
                    })
                    const chunkResp = await Promise.all(chunkPromises)
                    blockedUsers.push(...chunkResp)
                    hooks.setLoading(20 + ((createdUsers.length + updatedUsers.length + blockedUsers.length + usersWithWorkday.length)/totalCount)*80)
                }
                console.log({ blockedUsers })

                
                const allUserChunks = chunk(users, 50)
                for await (const chunk of allUserChunks){
                    const chunkPromises = chunk.map(async us => {
                        const user = [...createdUsers, ...updatedUsers].find(u => u.UID === us.UID)
                        if((user && user.id) || us.id){
                            return hooks.createWorkday[0]({
                                variables: {
                                    user: (user && user.id) || us.id,
                                    uploadrvi: record.id,
                                    absences: Number.isNaN(parseFloat(us.absences)) ? 0 : parseFloat(us.absences),
                                    workdayfraction: Number.isNaN(parseFloat(us.workdayfraction)) ? 0 : parseFloat(us.workdayfraction),
                                    // workeddays: user.workeddays
                                }
                            })
                        }
                        else{
                            console.log({ us })
                            throw new Error("Ocurrió un fallo al asignar jornadas de trabajo al usuario")
                        }
                    })
                    const chunkResp = await Promise.all(chunkPromises)
                    usersWithWorkday.push(...chunkResp)
                    hooks.setLoading(20 + ((createdUsers.length + updatedUsers.length + blockedUsers.length + usersWithWorkday.length)/totalCount)*80)
                }
                console.log({ usersWithWorkday })

                await hooks.refetchUsers()
                // await hooks.refetchTeams()
                // await hooks.refetchWorkdays()
                hooks.setLoading(false)
                message.success("La jerarquía de equipos y los usuarios se actualizaron correctamente")
                resolve({ ok: true })
            }

            catch(err){
                hooks.setLoading(false)
                console.error(err)
                message.error(err.message, 5)
                resolve({ ok: false })
            }
        }
    })
}

export default hadouken