import React from 'react'

const CheckCell = ({ checked, selected, value, onClick = () => console.log("Warning: No function has been passed to CheckCell onClick parameter!") }) => {
    const marked = checked || selected || value === "true"
    return (
        <div style={styles.container} onClick={() => onClick()}>
            <input style={styles.input} type="checkbox" checked={marked} onChange={e => e} />
            <span style={styles.check}>
                <span className="innerCheck" style={{...styles.cross, ...(marked ? styles.checked : {})}}>×</span>
            </span>
        </div>
    )
}

const styles = {
    container: {
        position: "relative",
        height: "1em"
    },
    input: {
        position: "absolute",
        opacity: 0,
        cursor: "pointer",
        height: 0,
        width: 0,
    },
    check: {
        position: "absolute",
        top: -2,
        left: 0,
        height: 20,
        width: 20,
        backgroundColor: "transparent",
        border: "2px solid gainsboro",
        borderRadius: 5,
        transition: "all 300ms",
    },
    cross: {
        position: "absolute",
        top: -12,
        // top: -22,
        left: 1,
        // left: -3,
        color: "transparent",
        fontSize: 27,
        // fontSize: 38,
        userSelect: "none",
        transition: "all 200ms",
    },
    checked: {
        color: "lightgray"
    }

}
export default CheckCell