import React, { useState, useEffect, useContext, useRef } from 'react'
import { LoadingContext, UploadSelectorContext, FinalContext } from '../../context'
import { GET_SINGLE_TEAM, GET_MODEL, GET_MY_FATHERS } from '../../graphql/queries'
import { withRouter, Link } from 'react-router-dom'
import { Container, Checkbox, TableLabel, Button } from '../../libraryComponent'
import { TeamCard, Table } from '../../component'
import { Row, Col, Tooltip, Popconfirm, Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { get, flattenDeep, uniqBy } from 'lodash'
import { EllipsisOutlined, CheckCircleTwoTone, ExclamationCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import styled from 'styled-components'
import { calculatervi } from '../../utils/calculatervi'
import { currency } from '../../utils/constantes'
import { UPDATE_RESULT_MODEL_VALIDATE } from '../../graphql/mutations'

const Team = ({ match, history }) => {
    const { value, setValue } = useContext(UploadSelectorContext);
    const contextRef = useRef(null)
    const notJump = useRef(null)
    const { value: valueFinal, setValue: setValueFinal } = useContext(FinalContext);
    const { setLoading } = useContext(LoadingContext)
    const [team, setTeam] = useState({})
    const [dataValidate, setDataValidate] = useState({
        resultsSingle: [],
        resultsSons: []
    })
    const [fathers, setFathers] = useState([])
    const [sons, setSons] = useState([])
    const [users, setUsers] = useState([])
    const [filteredUsers, setFilteredUsers] = useState([])
    // const [showCompleteTeam, setShowCompleteTeam] = useState(false)
    const [updateResultModel] = useMutation(UPDATE_RESULT_MODEL_VALIDATE);
    const [fetchMyFathers, { data: dataUser }] = useLazyQuery(GET_MY_FATHERS, {
        variables: {
            uploadrvi: value,
            final: valueFinal === "PROV" ? false : true,
            userid: JSON.parse(localStorage.getItem("user")).id
        }
    })

    const [fetchTeam, { loading, data, refetch }] = useLazyQuery(GET_SINGLE_TEAM, {
        variables: {
            // uploadrvi: value,
            // final: valueFinal === "PROV" ? false : true,
            // model: match.params.idmodel,
            teamID: match.params.idcet
        },
        // onCompleted: data => console.log({ data })
    })
    // const { data: dataModel } = useQuery(GET_MODEL, { variables: { model: match.params.idmodel }})

    const columns = [
        {
            render: row => <Avatar icon={<UserOutlined />} />

        },
        {
            title: 'Nombre',
            key: 'name',
            render: row => <TableLabel>{row.name + " " + row.lastname}</TableLabel>
        },
        {
            title: "Rol",
            dataIndex: "role",
            key: "level",
            render: row => <TableLabel>{row && (row.type === "manager" ? "Manager" : "Trabajador")}</TableLabel>
        },
        {
            title: "Modelo",
            key: "model",
            render: row => {
                const rm = Array.isArray(row.result_models) && row.result_models[0]
                const model = Array.isArray(row.kpis) && row.kpis.length > 0 && row.kpis.find(x => get(x, "model.uploadrvi.id", "") === value && valueFinal === (get(x, "model.final", false) ? "FINAL" : "PROV"))
                return get(rm ? rm : model, "model.name", "Sin asignar")
            }
        },
        {
            title: "Retribución",
            key: "retrib",
            render: row => {
                const rm = Array.isArray(row.result_models) && row.result_models.find(r => get(r, "model.uploadrvi.id") === value && valueFinal === (get(r, "model.final", false) ? "FINAL" : "PROV"))
                return rm ? calculatervi(rm) + currency : "-"
            }
        },
        {
            key: "btn",
            render: row => {
                const rm = Array.isArray(row.result_models) && row.result_models.find(r => get(r, "model.uploadrvi.id") === value && valueFinal === (get(r, "model.final", false) ? "FINAL" : "PROV"))
                const getIcon = () => {
                    // console.log(rm)
                    if (!rm) { return <Tooltip title="RVI no calculado"><EllipsisOutlined /></Tooltip> }
                    else if (!canValidate(rm) && rm.corrections.length > 0) { return <Tooltip title="RVI calculado, validado y revisado"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> }
                    else if (!canValidate(rm) && rm.corrections.length <= 0) { return <Tooltip title="RVI corregido y validado"><CheckCircleTwoTone twoToneColor="#faad14" /> </Tooltip> }
                    else { return <Tooltip title="RVI calculado, pendiente de validación"><EditTwoTone /></Tooltip> }
                }
                const model = row.kpis.map(k => k.model).find(m => m.final === (valueFinal === "FINAL") && m.uploadrvi.id === value)
                if (get(model, "id")) {
                    return <Link to={`/result/${model.id}/${row.id}`}>{getIcon()}</Link>
                }
            }
        }
    ];

    const canValidate = (result_model) => {
        // const managers = get(dataUser, "user.managerOf.fathers.map(x=>x.id).managers.map(x=>x.id)", [])
        const user = JSON.parse(localStorage.getItem("user")).id
        const managerOf = get(dataUser, "user.managerOf", [])
        const fathers = managerOf.length > 0 ? flattenDeep(managerOf.map(x => x.fathers)) : []
        const managers = fathers.length > 0 ? flattenDeep(fathers.map(x => x.managers)) : []
        const managersId = managers.length > 0 ? flattenDeep(managers.map(x => x.id)) : []

        const validated = get(result_model, "validated", [])

        // console.log({ result_model, validated: validated.filter(x => managersId.includes(x)).length > 0 ? false : true })
        return validated === null || validated.length === 0 ? true : validated.filter(x => [...managersId, user].includes(x)).length > 0 ? false : true;
    }

    useEffect(() => {
        setLoading(true)
    }, [])

    useEffect(() => {
        if (loading === false && data) {
            setLoading(false)
        }
    }, [data, loading])

    useEffect(() => {
        if (value && !contextRef.current) {
            fetchTeam()
            fetchMyFathers()
            contextRef.current = { uploadrvi: value, final: valueFinal }
        }
        else if (value && !!contextRef.current && (value !== contextRef.current.uploadrvi || valueFinal !== contextRef.current.final)) {
            if (!notJump.current) {
                history.push("/hierarchy")
            }
            else {
                notJump.current = false
            }

        }
    }, [value, valueFinal])

    useEffect(() => {
        try {
            const user = JSON.parse(localStorage.getItem("user"))
            if (data) {

                const team = get(data, "teams[0]", {})
                // console.log({ teamValue: team.uploadrvi.id,  value, teamFinal: team.final, valueFinal})
                if (team.uploadrvi.id !== value) {
                    setValue(team.uploadrvi.id)
                    notJump.current = true;
                }
                if (team.final === false && valueFinal === "FINAL") {
                    setValueFinal("PROV")
                    notJump.current = true;
                }
                else if (team.final === true && valueFinal === "PROV") {
                    setValueFinal("FINAL")
                    notJump.current = true;
                }

                const managers = data.teams[0].managers.map(m => m.id)
                const fatherManagers = data.teams[0].fathers.map(f => f.managers.map(m => m.id))
                const accessList = flattenDeep([managers, fatherManagers])
                if (!accessList.includes(user.id) && user.role.type !== "admin") {
                    history.push("/hierarchy")
                }
                const fathersOrdered = [...data.teams[0].fathers].sort((a, b) => {
                    if (a.level < b.level) { return 1 }
                    else { return -1 }
                })
                const directSons = data.teams[0].sons.filter(s => {
                    const directFather = [...s.fathers].sort((a, b) => {
                        if (a.level > b.level) { return 1 }
                        else { return -1 }
                    }).slice(-1)[0]
                    return directFather.id === data.teams[0].id
                })
                const t = get(data, "teams[0]")
                const users = get(data, "teams[0].workers", [])
                const usersF = users.filter(u => {
                    const kpis = u.kpis.map(k => get(k, "model.id"))
                    return kpis.includes(match.params.idmodel)
                })
                setTeam(t)
                const fathersComplete = [...fathersOrdered, data.teams[0]].map((t, i, arr) => {
                    const canLink = flattenDeep(arr.slice(0, i + 1).map(te => te.managers.map(m => m.id))).includes(user.id) || user.role.type === "admin"
                    return { ...t, canLink }
                }).sort((a, b) => {
                    if (a.level > b.level) { return 1 }
                    else { return -1 }
                })

                const result_models_users = users.map(x => x.result_models);
                const result_models_sons = data.teams[0].sons;
                const result_models_sons_managers = result_models_sons.map(x => x.managers.map(y => y.result_models))
                const result_models_sons_workers = result_models_sons.map(x => x.workers.map(y => y.result_models))

                const resultsSingle = uniqBy(flattenDeep(result_models_users).filter(x => x.model.uploadrvi.id === value && valueFinal === (get(x, "model.final", false) ? "FINAL" : "PROV") && canValidate(x)), "id");
                const resultsSons = uniqBy(flattenDeep([result_models_users, result_models_sons_managers, result_models_sons_workers]).filter(x => x.model.uploadrvi.id === value && valueFinal === (get(x, "model.final", false) ? "FINAL" : "PROV") && canValidate(x)), "id");


                setDataValidate({ resultsSingle, resultsSons })

                setFathers(fathersComplete)
                setUsers(users)
                setSons(directSons)
                // setFilteredUsers(usersF)
                setFilteredUsers(users)
            }
        } catch (err) { console.error(err) }
    }, [data])

    return (
        <Container>
            <Breadcrumbs>
                {fathers.length > 1 && fathers.map((f, i, arr) => [
                    <Link disabled={!f.canLink} key={`bread-${f.id}-link`} to={"/team/" + f.id}>
                        <span>{f.name}</span>
                    </Link>,
                    <span key={`bread-${f.id}-chev`} className="chevron">{arr.length !== i + 1 ? " > " : ""}</span>
                ])}
            </Breadcrumbs>

            <Row>
                <Col md={8} sm={24}>
                    <h1 style={{ marginBottom: 0 }}>{team.name}</h1>
                </Col>
            </Row>

            <div style={{ display: "flex", margin: "1em 0 2em 0" }}>
                <Popconfirm
                    placement="bottom"
                    title={`¿Seguro que desea validar el equipo ${team.name}?`}
                    okText="Si"
                    cancelText="No"
                    disabled={dataValidate.resultsSingle.length === 0}
                    onConfirm={() => {
                        setLoading(true);
                        const { resultsSingle } = dataValidate;
                        const user = JSON.parse(localStorage.getItem("user"))
                        Promise.all(resultsSingle.map(x => {
                            // console.log({ id: x.id, validated: x.validated && x.validated.length > 0 ? [...x.validated, user._id] : [user._id] })
                            updateResultModel({ variables: { id: x.id, validated: x.validated && x.validated.length > 0 ? [...x.validated, user._id] : [user._id] } })
                        })).then(() => refetch()).then(() => setLoading(false))
                    }}
                >
                    <Button type="green">
                        Validar RVI del equipo {team.name}
                    </Button>
                </Popconfirm>

                &nbsp;&nbsp;

                {Array.isArray(sons) && sons.length > 0 && <Popconfirm placement="bottom" title={`¿Seguro que desea validar el equipo ${team.name} y sus hijos?`} onConfirm={() => {
                    setLoading(true);
                    const { resultsSons } = dataValidate;
                    const user = JSON.parse(localStorage.getItem("user"))
                    Promise.all(resultsSons.map(x => updateResultModel({ variables: { id: x.id, validated: x.validated && x.validated.length > 0 ? [...x.validated, user._id] : [user._id] } }))).then(() => refetch()).then(() => setLoading(false))
                }} okText="Si" cancelText="No" disabled={dataValidate.resultsSons.length === 0}>
                    <Button type="green">
                        Validar RVI del equipo {team.name} y sus hijos
                            </Button>
                </Popconfirm>}
            </div>

            {
                Array.isArray(sons) && sons.length > 0 && [
                    <h3 key="sons-title">Equipos hijos</h3>,
                    <Row key="sons-row" gutter={[18, 18]}>
                        {sons.map(s => (
                            <Col key={"son-" + s.id} xs={24} sm={12} md={8}>
                                <TeamCard team={s} model={match.params.idmodel} />
                            </Col>
                        ))}
                    </Row>
                ]
            }

            {Array.isArray(team.sons) && team.sons.length > 0 && <br />}

            {
                (filteredUsers || loading) && [
                    <h3 key="title">Trabajadores</h3>,
                    <Table
                        key="table"
                        rowKey="id"
                        loading={loading}
                        columns={columns}
                        dataSource={users}
                    // dataSource={showCompleteTeam ? users : filteredUsers}
                    />,
                    // <div key="separator" style={{ height: ((showCompleteTeam && users.length === 0) || (!showCompleteTeam && filteredUsers.length === 0)) ? "1em" : 0 }}></div>,
                    <div key="separator" style={{ height: filteredUsers.length === 0 ? "1em" : 0 }}></div>
                ]
            }

            <br />
        </Container >
    )
}

const Breadcrumbs = styled.div`
    margin-bottom: 1em;
    span {
        color: rgb(153, 153, 153);
        font-weight: 600;
    }
    .chevron {
        padding: 0 10px;
    }
`

export default withRouter(Team)