export const currency = "€";
export const itemsFirstSelect = [
    { key: "INDIVIDUAL", value: "Objetivos Individuales" },
    { key: "COLLECTIVE", value: "Objetivos Colectivos" },
    { key: "PENALIZER", value: "Penalizadores" },
    { key: "BONUS", value: "Bonus" },
    { key: "GLOBAL", value: "Datos Globales" },
]

const valueNoBonus = [{ key: 1, value: "Mayor que" }, { key: 2, value: "Mayor o igual que" }, { key: 3, value: "Igual a" }, { key: 4, value: "Menor o igual que" }, { key: 5, value: "Menor que" }, { key: 6, value: "Distinto de" }];
const valueBonus = [{ key: 7, value: "Se cumple" }, { key: 8, value: "No se cumple" }];

export const itemsThirdSelect = {
    INDIVIDUAL: valueNoBonus,
    COLLECTIVE: valueNoBonus,
    GLOBAL: valueNoBonus,
    PENALIZER: valueNoBonus,
    BONUS: valueBonus
}

export const itemsFifthSelect = [
    { key: "INDIVIDUAL", value: "Objetivos Individuales" },
    { key: "COLLECTIVE", value: "Objetivos Colectivos" },
    { key: "RVI", value: "Total RVI" }
]

export const itemsSeventhSelect = [
    { key: "+", value: "+" },
    { key: "-", value: "-" }
]

export const itemsNinethSelect = [
    { key: "%", value: "%" },
    { key: currency, value: currency }
]

export const kidBase = "0018";
export const kidTeoricos = "0017";