import { round } from 'lodash';
// VERSIÓN QUE PISA LAS MODIFICACIONES

export const calculatervi = result_model => {
    if (result_model) {
        const { amount_rvi, result, corrections, kpis } = result_model;
        if (Array.isArray(corrections) && corrections.length > 0) {
            // console.log(corrections.slice(-1)[0])
            return round(corrections.slice(-1)[0].value, 2) || 0
        }
        else {
            const amount_conditioned_kids = result.filter(x => x.kid !== "RVI").map(x => {
                const findKpi = kpis.find(y => y.kid === x.kid)
                return x.amount_conditioned > 0 ? x.amount_conditioned : Math.abs(x.amount_conditioned) >= findKpi.amount ? findKpi.amount * -1 : x.amount_conditioned;
            });
            const rviWithKpi = amount_rvi + amount_conditioned_kids.reduce((a, b) => a + b, 0);
            // const rviWithRvi = rviWithKpi + (rviWithKpi * result.filter(x => x.kid === "RVI").reduce((a, b) => a + b.amount_conditioned, 0) / 100);
            const amount_conditioned_complete = result.filter(x => x.kid === "RVI");
            const amount_conditioned_complete_perc = amount_conditioned_complete.reduce((a, b) => a + b.amount_conditioned.filter(x => x.intEntoncesMode === "%").reduce((c, d) => c + d.intEntonces, 0), 0)
            const amount_conditioned_complete_fix = amount_conditioned_complete.reduce((a, b) => a + b.amount_conditioned.filter(x => x.intEntoncesMode === "€").reduce((c, d) => c + d.intEntonces, 0), 0)
            const rviFinal = rviWithKpi + (rviWithKpi * amount_conditioned_complete_perc / 100) + amount_conditioned_complete_fix
            const finalrviFinal = rviFinal <= 0 ? 0 : rviFinal;
            return round(finalrviFinal, 2)
        }
    } else {
        return 0
    }
}

// VERSIÓN QUE ACUMULA LAS MODIFICACIONES

// export const calculatervi = result_model => {
//     if (result_model) {
//         const { amount_rvi, result, corrections } = result_model;
//         console.log({ amount_rvi, result, corrections })
//         const rviWithKpi = amount_rvi + result.filter(x => x.kid !== "RVI").reduce((a, b) => a + b.amount_conditioned, 0);
//         const rviWithRvi = rviWithKpi + (rviWithKpi * result.filter(x => x.kid === "RVI").reduce((a, b) => a + b.amount_conditioned, 0) / 100);
//         const rviWithCorrections = rviWithRvi + corrections.reduce((a, b) => a + parseFloat(b.value), 0);
//         return rviWithCorrections < 0 ? 0 : rviWithCorrections;
//     } else {
//         return 0
//     }
// }