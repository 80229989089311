import React, { useEffect, useState, useContext } from 'react'
import { Select } from '..';
import FinalContext from '../../context/FinalContext';

const FinalSelector = ({ }) => {
    const { value, setValue } = useContext(FinalContext);
    const items = [{ key: "PROV", value: "Provisional" }, { key: "FINAL", value: "Final" }]

    useEffect(() => {
        const lastFinalSelection = items.find(it => it.key === window.localStorage.getItem("lastFinalSelection"))
        setValue(lastFinalSelection ? lastFinalSelection.key : items[0].key)
    }, [])
    
    return <Select items={items} handleChange={value => {
        setValue(value)
        window.localStorage.setItem("lastFinalSelection", value)
    }} mode={null} value={value} />
}

export default FinalSelector;