import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { Table, NewUploadRVIModal } from '../../component'
import { Container, Button, Dragger } from '../../libraryComponent'
import { GET_UPLOAD_RVIS, GET_MODELS, GET_ROLES, GET_TEAMS, GET_USERS_JUST_UID, GET_WORKDAYS, GET_MODELS_KPIS } from '../../graphql/queries'
import moment from 'moment'
import { get, capitalize, chunk } from 'lodash'
import { Upload as UploadAntd, message } from 'antd'
import { UPDATE_WORKDAY, UPLOAD_FILE, UPDATE_UPLOADRVI, CREATE_MODEL, CREATE_TEAM, CREATE_USER, UPDATE_USER, DELETE_TEAM, DELETE_USER, CREATE_WORKDAY, DELETE_WORKDAY, DELETE_MODEL, DELETE_KPI } from '../../graphql/mutations'
import styled from 'styled-components'
import { hadouken } from '../../utils'
import { uploadModel } from '../../utils/uploadModel'
import LoadingContext from '../../context/LoadingContext'

const Upload = () => {
    const [variables, setVariables] = useState({
        uploadrvi: "",
        final: ""
    })
    const [modalUploadRVI, setModalUploadRVI] = useState(false)
    const { setLoading } = useContext(LoadingContext)

    // LINEAS A DESCOMENTAR PARA QUE FUNCIONEN LOS NUKE BUTTONS
    const { data: dataTeams, refetch: refetchTeams } = useQuery(GET_TEAMS)
    const { data: dataWorkdays, refetch: refetchWorkdays } = useQuery(GET_WORKDAYS)
    const { data: dataModelKpis, refetch: refetchModelKpis } = useQuery(GET_MODELS_KPIS)
    const [deleteTeam] = useMutation(DELETE_TEAM)
    const [deleteUser] = useMutation(DELETE_USER)
    const [deleteWorkday] = useMutation(DELETE_WORKDAY)
    const [deleteModel] = useMutation(DELETE_MODEL)
    const [deleteKpi] = useMutation(DELETE_KPI)
    // HASTA AQUI

    const { data: dataUploadrvis, loading: loadingUploadrvis } = useQuery(GET_UPLOAD_RVIS)
    const { data: dataRoles } = useQuery(GET_ROLES)
    const { data: dataUsers, refetch: refetchUsers } = useQuery(GET_USERS_JUST_UID)

    useEffect(() => { setLoading(true) }, [])
    useEffect(() => {
        const loadArr = [dataUploadrvis, dataRoles, dataUsers].filter(e => !e)
        if (dataUploadrvis && dataRoles && dataUsers) { setLoading(false) }
        else { setLoading((100 - (loadArr.length / 3) * 100) + 0.01) }
    }, [dataUploadrvis, dataRoles, dataUsers])

    const [uploadFiles] = useMutation(UPLOAD_FILE, { onError: err => console.log(JSON.stringify(err)) })
    const [updateUploadkpi] = useMutation(UPDATE_UPLOADRVI, {
        onError: err => console.log(JSON.stringify(err)),
        refetchQueries: [{ query: GET_UPLOAD_RVIS }]
    })
    const [createModel] = useMutation(CREATE_MODEL, {
        refetchQueries: [
            {
                query: GET_MODELS,
                variables
            }
        ]
    });

    const [updateWorkday] = useMutation(UPDATE_WORKDAY);

    const hadoukenHooks = {
        createTeam: useMutation(CREATE_TEAM),
        // refetchTeams,
        createUser: useMutation(CREATE_USER),
        updateUser: useMutation(UPDATE_USER),
        refetchUsers,
        createWorkday: useMutation(CREATE_WORKDAY),
        // refetchWorkdays,
        setLoading
    }
    const hadoukenData = {
        roles: dataRoles && dataRoles.roles,
        users: dataUsers && dataUsers.users
    }

    const handleCell = (record, column, i) => {
        const arr = ["provisional_hierarchy", "provisional_data", "final_hierarchy", "final_data"]
        const actualIndex = arr.findIndex(it => it === column)
        const arrCol = arr.slice(0, actualIndex)
        const file = get(record, `files[${column}]`)
        if (file) {
            return (
                <HaveFile>
                    <a href={process.env.REACT_APP_API_URL + file.url} target="_blank" rel="noreferrer noopener"><Button size="small">{file.name}</Button></a>
                    <small>{`Subido el ${moment(file.createdAt).format("YYYY-MM-DD HH:mm")}`}</small>
                    {!record.files[arr[actualIndex + 1]] &&
                        <UploadAntd showUploadList={false} customRequest={e => e} onChange={event => handleUpload(event, record, column, true)}><OtherFile>Subir otro archivo</OtherFile></UploadAntd>
                    }
                </HaveFile>
            )
        }
        else if (arrCol.every(col => get(record, `files[${col}]`))) {
            return <Dragger clear={!!(i % 2)} onChange={evt => handleUpload(evt, record, column)} />
        }
        else {
            return "Aún no disponible"
        }
    }

    const handleUpload = async (event, record, column, replaceObj) => {
        if (event.file) {
            let response = undefined
            const file = replaceObj ? event.file.originFileObj : event.file
            if (column === "provisional_hierarchy") {
                if (replaceObj) {
                    // console.log({ record })
                    fetch(process.env.REACT_APP_API_URL + "/uploadrvis/delete_related/" + record.id + "/provisional_hierarchy", {
                        headers: {
                            Accept: "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("jwt")}`
                        }
                    })
                }
                response = await hadouken(file, record, hadoukenHooks, hadoukenData, false)
            }
            else if (column === "final_hierarchy") {
                if (replaceObj) {
                    // console.log({ record })
                    fetch(process.env.REACT_APP_API_URL + "/uploadrvis/delete_related/" + record.id + "/final_hierarchy", {
                        headers: {
                            Accept: "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("jwt")}`
                        }
                    })
                }
                response = await hadouken(file, record, hadoukenHooks, hadoukenData, true)
            }
            else if (column === "provisional_data") {
                if (replaceObj) {
                    // console.log({ record })
                    fetch(process.env.PUBLIC_URL + "/uploadrvis/delete_related/" + record.id + "/provisional_data", {
                        headers: {
                            Accept: "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("jwt")}`
                        }
                    })
                }
                setVariables({
                    uploadrvi: record.id,
                    final: false
                })
                response = await uploadModel(record, true, file, createModel, setLoading, updateWorkday)
            }
            else if (column === "final_data") {
                if (replaceObj) {
                    // console.log({ record })
                    fetch(process.env.PUBLIC_URL + "/uploadrvis/delete_related/" + record.id + "/final_data", {
                        headers: {
                            Accept: "multipart/form-data",
                            Authorization: `Bearer ${localStorage.getItem("jwt")}`
                        }
                    })
                }
                setVariables({
                    uploadrvi: record.id,
                    final: true
                })
                response = await uploadModel(record, false, file, createModel, setLoading, updateWorkday)
            }

            const uploaded = get(await uploadFiles({ variables: { file } }), "data.upload")
            // console.log({ uploaded })

            if (response.ok) {
                updateUploadkpi({
                    variables: {
                        id: record.id,
                        files: {
                            ...record.files,
                            [column]: {
                                createdAt: uploaded.createdAt,
                                name: uploaded.name,
                                url: uploaded.url
                            }
                        }
                    }
                })
            }
        }
    }


    return (
        <Container>
            <h1>Subida de archivos</h1>
            <Table
                rowKey="id"
                loading={loadingUploadrvis}
                columns={[
                    {
                        title: "Fecha",
                        render: row => capitalize(moment(row.date_start).format("MMMM YYYY"))
                    },
                    {
                        title: "Jerarquía Provisional",
                        render: (text, record, index) => handleCell(record, "provisional_hierarchy", index)
                    },
                    {
                        title: "Datos Provisionales",
                        render: (text, record, index) => handleCell(record, "provisional_data", index)
                    },
                    {
                        title: "Jerarquía Final",
                        render: (text, record, index) => handleCell(record, "final_hierarchy", index)
                    },
                    {
                        title: "Datos Final",
                        render: (text, record, index) => handleCell(record, "final_data", index)
                    }
                ]}
                dataSource={get(dataUploadrvis, "uploadrvis", [])}
            />

            <div style={{ display: "flex" }}>
                <Button type="green" onClick={() => setModalUploadRVI(true)}>Crear nueva subida de archivos</Button>
                <span>&nbsp;</span>
            </div>
            <br />
            <NewUploadRVIModal visible={modalUploadRVI} setVisible={setModalUploadRVI} uploadrvis={dataUploadrvis && dataUploadrvis.uploadrvis} />
        </Container>
    )
}

const HaveFile = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    a{
        margin-bottom: 10px;
    }
`
const OtherFile = styled.small`
    text-decoration: underline;
    color: rgb(69,144,122);
    cursor: pointer;
    &:hover{
        filter: saturate(1.5);
    }
`

export default Upload