import React, { useContext } from 'react';
import moment from 'moment';
import { CloseCircleOutlined } from '@ant-design/icons';
import { CopiedConditionsContext } from '../../context';
import { TableLabel } from '../../libraryComponent';

const CopiedConditions = ({ }) => {
    const { value, setValue } = useContext(CopiedConditionsContext);
    return value ? <div> <TableLabel>Modelo copiado para hacer de plantilla de Condiones: {value.name} {moment(value.createdAt).format("MMMM YYYY")}</TableLabel> <CloseCircleOutlined onClick={() => setValue(null)} /></div> : null
}

export default CopiedConditions;