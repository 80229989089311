import React from 'react';
import styled from 'styled-components'
import { DatePicker } from "antd";

const { MonthPicker } = DatePicker;

const RVIMonthPicker = ({ value, placeholder, format, onChange, ...rest }) => {
    return (
        <Wrapper>
            <MonthPicker
                value={value}
                placeholder={placeholder}
                format={format}
                onChange={(date, dateString) => onChange(date, dateString)}
                {...rest}
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .ant-picker{
        border-radius: 8px;
    }
    .ant-picker-focused {
        border-color: rgb(69,144,122);
        border-right-width: 1px !important;
        outline: 0;
        box-shadow: 0 0 0 2px rgba(69,144,122, 0.2);
    }
    .ant-picker:hover, .ant-picker-focused {
        border-color: rgb(69,144,122);
        border-right-width: 1px !important;
    }
`

export default RVIMonthPicker;