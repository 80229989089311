import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ImageCard, FooterCard } from '../../libraryComponent'

const UserCard = ({ name, email, job, image, round, footer, rvi, color, team, style }) => {
    return (
        <Card footer={footer} style={style}>
            <ImageCard round={round} image={image} />
            <DataWrapper>
                { name && <Name>{ name }</Name>}
                { email && <Email>{ email }</Email>}
                { team && <Team>{ team.name }</Team> }
                {/* { team && <Link to={"/team/" + team.id}><Team>{ team.name }</Team></Link> } */}
                { job && <Job>{ job }</Job>}
            </DataWrapper>
            {
                footer && <FooterCard rvi={rvi} color={color} />
            }
        </Card>
    )
}

const Card = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    align-items: center;
    height: ${({ footer }) => footer ? "320px" : "220px"};
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
`
const DataWrapper = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    padding: 1em;
    span:not(:last-child){
        margin-bottom: 3px;
    }
`
const Name = styled.span`
    font-size: 1.3em;
    line-height: 1.3em;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.65);
`
const Email = styled.span`
    color: #999;
    max-width: 100%;
    overflow-wrap: break-word;
`
const Team = styled.span`
    font-weight: bold;
    color: #999;
    margin-bottom: 5px;
`
const Job = styled.span`
    font-weight: bold;
    line-height: 1.3em;
`
export default UserCard