import React from 'react'
import styled from 'styled-components'
import { Checkbox as CheckboxAntd } from 'antd'

const Checkbox = ({ children, ...rest}) => {
    return (
        <Wrapper>
            <CheckboxAntd {...rest}>{ children }</CheckboxAntd>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    .ant-checkbox-checked .ant-checkbox-inner{
        background-color: #4c935b;
        border-color: #4c935b;
    }
    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner, .ant-checkbox-checked:hover .ant-checkbox-inner{
        border-color: #4c935b;
    }
`

export default Checkbox