import React from 'react'
import { Bar } from '..'
import styled from 'styled-components'
import { gradients } from '../../utils/gradients'

const CardSingleBonus = ({ title, achievement }) => {
    const color = "green"

    return (
        <Wrapper>
            <h4>{title}</h4>
            <LowerWrapper>
                <div>
                    <small>Bonus</small>
                    <span className="objetive">{achievement}</span>
                </div>
            </LowerWrapper>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    position: relative;
    padding: 10px;
`
const LowerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    flex: 1;
    &>div{
        display: flex;
        flex-direction: column;
    }
    .result{
        font-size: 1.6em;
        font-weight: bold;
    }
    .objetive{
        font-size: 1.1em;
        font-weight: bold;
    }
`

const RVIBox = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    background: ${({ color }) => gradients[color]};
    padding: 5px;
    color: white;
    border-radius: 0 0 0 8px;
`

export default CardSingleBonus