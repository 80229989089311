import React from "react";
import styled from 'styled-components'
import { Upload } from "antd";
import { Button } from '../../libraryComponent'
import { UploadOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const RVIDragger = props => {
  return (
    <Wrapper clear={props.clear}>
      <Dragger {...props} beforeUpload={() => false} fileList={[]}>
        <Button type="green">
          <UploadOutlined />
          Subir Archivo
        </Button>
      </Dragger>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .ant-upload.ant-upload-drag{
    border-radius: 8px;
    background: ${({ clear }) => clear ? "#fcfcfc" : "#f7f7f7"};
  }
  .ant-upload.ant-upload-drag:hover{
    border-color: rgb(69,144,122);
  }
`

export default RVIDragger;
