import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const Logout = ({ history }) => {
    useEffect(() => {
        window.localStorage.clear()
        history.push("/auth/login")
    }, [])
    return (
        <div>
            <h4>Cerrando sesión...</h4>
        </div>
    )
}

export default withRouter(Logout)