import React, { useContext, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { Modal, Button } from 'antd'
import { LoadingContext } from '../../context'
import styled from 'styled-components'

const ModalCalculate = ({ visible, setVisible, modalInfo, updateModel, refetch, history }) => {
    const { setLoading } = useContext(LoadingContext)
    return (
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            closable={true}
            footer={<WrapperButton>
                <Button type="primary" onClick={async () => {
                    setLoading(true)
                    if (!modalInfo.calculate) {
                        // mando a borrar todos los results_model
                        const { result_models } = modalInfo;
                        const resultProm = result_models.map(result_model => {
                            return fetch(process.env.REACT_APP_API_URL + "/result-models/" + result_model.id, {
                                method: "DELETE",
                                headers: {
                                    Accept: "multipart/form-data",
                                    Authorization: `Bearer ${localStorage.getItem("jwt")}`
                                }
                            })
                        })
                        const resultResp = await Promise.all(resultProm)
                        console.log({ resultResp })
                    }
                    await updateModel({
                        variables: {
                            id: modalInfo.id,
                            ready: true
                        }
                    })
                    if(modalInfo.calculate){
                        await refetch()
                    }
                    setLoading(false)
                    // updatealmodelo con ready true
                }}>{modalInfo.calculate ? "Calcular" : "Recalcular"}</Button>
                <Button onClick={() => history.push("/conditionals?model=" + modalInfo.id )}>Ver condiciones del modelo</Button>
                <Button type="danger" onClick={() => setVisible(false)}>Cancelar</Button>
            </WrapperButton>}
        >
            <Wrapper>
                <Title>{
                    modalInfo.calculate ?
                        `Vas a proceder a calcular el modelo ${modalInfo.model}. Recomendamos que revise que todo este correcto antes de continuar.` :
                        `Vas a proceder a recalcular el modelo ${modalInfo.model}. Esto borrará todo lo anteriormente calculado.`
                }</Title>
            </Wrapper>
        </Modal>
    )
}


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const WrapperButton = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    justify-content: space-evenly;
`
const Title = styled.h3`
    width: 100%;
    text-align: left;
    font-weight: bold;
`

export default withRouter(ModalCalculate);