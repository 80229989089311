import React, { useEffect, useState, useContext } from 'react';
import {
    WarningOutlined,
    EditOutlined,
    HighlightOutlined
} from '@ant-design/icons';
import { ModalCreateValidation } from '..';
import {get} from 'lodash'
import { Button } from '../../libraryComponent'
import styled from 'styled-components'
import { UPDATE_RESULT_MODEL_VALIDATE } from '../../graphql/mutations';
import { useMutation } from '@apollo/react-hooks';
import { LoadingContext } from '../../context';

const ValidationItem = ({ resultUser, result_model, rvi, refetch, validated }) => {
    const [visible, setVisible] = useState(false)
    const [canValidate, setCanValidate] = useState(false);
    const { setLoading } = useContext(LoadingContext);
    const [updateResultModel] = useMutation(UPDATE_RESULT_MODEL_VALIDATE);

    const user = JSON.parse(localStorage.getItem("user"));
    // const newrvi = result_model && result_model.corrections.length > 0 ? rvi + result_model.corrections.reduce((a, b) => a + parseFloat(b.value), 0) : 0;

    useEffect(() => {
        // setCanValidate(resultUser !== user.id)
        if(get(user, "role.type", "") === "authenticated"){
            setCanValidate(false)
        }else{
            setCanValidate(resultUser !== user.id && validated)
        }
    }, [])

    return result_model && (
        <Wrapper>
            <ModalCreateValidation user={user} result_model={result_model} visible={visible} setVisible={visible => setVisible(visible)} refetch={() => refetch()} canModify={canValidate} />
            <Flex>
                <Button size="small" type="green" onClick={() => setVisible(true)}>{setCanValidate ? <EditOutlined /> : <WarningOutlined />}Revisar RVI</Button>
                {/* { !result_model.validated && canValidate && (
                    <Button size="small" type="yellow" onClick={() => {
                        setLoading(true)
                        updateResultModel({ variables: { id: result_model.id, validated: true } }).then(() => refetch()).then(() => setLoading(false))
                    }}><HighlightOutlined /> Validar RVI</Button>
                )} */}
            </Flex>
        </Wrapper >
    )
}

const Wrapper = styled.div`
    margin-top: -10px;
    width: 100%;
    padding: 0 1em 1em 1em;
    text-align: center;
`
const Flex = styled.div`
    display: flex;
    justify-content: center;
    & > div {
        margin: 0 0.5em;
    }
`

export default ValidationItem;