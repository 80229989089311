import React, { useEffect, useRef } from 'react'
import { Table as TableAntd, Empty } from 'antd'
import styled from 'styled-components'

const Table = ({ ...rest }) => {
    // const tableRef = useRef(null)

    return (
        <StyledWrapper>
            <TableAntd 
                { ...rest }
                // components={{
                //     header: {
                //         wrapper: ({ children }) => (
                //             <thead className="ant-table-thead" ref={tableRef}>
                //                 { children }
                //             </thead>
                //         )
                //     }
                // }}
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No hay datos" />
                }}
            />
        </StyledWrapper>
    )
}

const StyledWrapper = styled.div`
    font-family: "Poppins";
    .ant-table{
        border-radius: 15px;
        border: 1px solid #f3f3f3;
        box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.05);
        overflow-y: hidden;
        overflow-x: auto;
    }
    th.ant-table-cell{
        background-color: transparent;
        font-weight: 600;
        color: lightgrey;
        border-bottom: 2px solid #f3f3f3;
    }
    tr.ant-table-row{
        &:nth-child(odd){
            background-color: transparent;
        }
        &:nth-child(even){
            background-color: #f8f8f8;
        }
    }
    td.ant-table-cell{
        border: none;
    }
    .ant-table-pagination{
        a {
            border-width: 0px;
            box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            font-family: "Poppins";
            font-weight: 600;
            transition: all 300ms;
        }
        li{
            border-width: 0;
            &:hover a{
                color: #bbb;
            }
        }
        .ant-pagination-item {
            a{
                color: #999
            }
            &.ant-pagination-item-active{
                a {
                    color: #666
                    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.4);
                }
            }
    }
`

export default Table