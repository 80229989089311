import React from 'react'
import styled from 'styled-components'

const FooterCard = ({ color, rvi }) => {
    return (
        <Wrapper>
            <UpperRow>
                <Text>Retribución variable</Text>
                <Quantity>{ rvi }</Quantity>
            </UpperRow>
            <ColorLine color={color}>
                <Deepener />
            </ColorLine>
        </Wrapper>
    )
}

const gradients = {
    green: "linear-gradient(90deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%);",
    yellow: "linear-gradient(90deg, rgba(249,190,80,1) 0%, rgba(248,147,53,1) 100%);",
    red: "linear-gradient(90deg, rgba(228,66,82,1) 0%, rgba(175,19,40,1) 100%);"
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`
const UpperRow = styled.div`
    padding: 0 1em;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
`
const Text = styled.span`
    font-size: 0.8em;
    color: #999;
`
const Quantity = styled.span`
    font-size: 2em;
    font-weight: bold;
`
const Deepener = styled.div`
    height: 10px;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.1) 100%);
`
const ColorLine = styled.div`
    height: 100%;
    width: 100%;
    background: ${({ color }) => gradients[color]};
`

export default FooterCard