import React from 'react'
import styled from 'styled-components'
import {
    WarningOutlined
} from '@ant-design/icons';
import { gradients } from '../../utils/gradients'
import { ValidationItem } from '../../component'
import { currency } from '../../utils/constantes';
import { round } from 'lodash'
import { Button } from '..';

const CardSimple = ({ title, value, bigvalue, linecolor, bonus, rvi, resultUser = null, result_model = null, refetch = null, notSoSimple, style, validated = false }) => {

    const corrections = (result_model && notSoSimple) && result_model.corrections.length > 0 ? result_model.corrections.slice(-1)[0].value : undefined
    // const corrections = (result_model && notSoSimple) && result_model.corrections.length > 0 ? rvi + result_model.corrections.reduce((a, b) => a + parseFloat(b.value), 0) : undefined
    const valRound = round(value, 2)
    const valueMod = notSoSimple ? (corrections !== undefined ? <><strike style={{ opacity: 0.6 }}>{valRound + currency}</strike>&nbsp;<strong>{corrections + currency}</strong></> : valRound + currency) : valRound
    return (
        <Wrapper style={style}>
            {linecolor && <Line color={linecolor} />}
            <span className="title">{title}</span>
            <span className={`value${bigvalue ? " big" : ""}`}>{bonus ? value === 1 ? "Sí" : value === 0 ? "No" : "-" : (!Number.isNaN(valueMod) && valueMod)}</span>
            {resultUser && validated && <Flex><Button size="small" type="yellow" onClick={() => { }}><WarningOutlined />RVI Validado</Button></Flex>}
            <ValidationItem validated={validated} resultUser={resultUser} result_model={result_model} refetch={() => refetch()} rvi={rvi} />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 1em;
    .title{
        margin-top: 10px;
        text-align: center;
        font-weight: bold;
    }
    .value{
        text-align: center;
        font-weight: bold;
        font-size: 1.6em;
        &.big{
            font-size: 3em;
        }
        margin-bottom: 10px;
    }
`

const Line = styled.div`
    height: 5px;
    width: 100%;
    background: ${({ color }) => gradients[color]};
`

const Flex = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 0.5em;
    & > div {
        margin: 0 0.5em;
    }
`

export default CardSimple
