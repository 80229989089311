import React from 'react';

//APOLLO
import { /*HttpLink, */InMemoryCache, ApolloClient, concat } from '@apollo/client';
import { ApolloProvider } from "@apollo/react-hooks"
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createUploadLink as HttpLink } from 'apollo-upload-client'
import { message } from 'antd';

let setLoading = e => e

const backToLogin = async networkError => {
    const jwt = localStorage.getItem("jwt");

    !jwt && localStorage.clear();
    if (networkError && networkError.statusCode === 401) {
        // remove cached token on 401 from the server
        // token = undefined;
        localStorage.clear();
    }
};

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });

const withToken = setContext(async request => {
    let token;
    if (!token) {
        token = localStorage.getItem("jwt");
    }
    const Authorization = token ? { Authorization: `Bearer ${token}` } : {}
    return {
        headers: {
            Accept: "application/json",
            ...Authorization
        }
    };
});

const resetToken = onError(({ networkError, graphQLErrors, response }) => {
    console.log({ networkError, graphQLErrors })
    if (networkError) {
        // console.log({ networkError })
        setLoading(false)
        message.error(`Error ${networkError && networkError.statusCode} durante la carga de datos`)
        backToLogin(networkError);
    }
    if (graphQLErrors) {
        // console.log({ graphQLErrors })
        setLoading(false)
        message.error("Error de GraphQL")
    }
});

const authFlowLink = withToken.concat(resetToken);

const link = authFlowLink.concat(httpLink);

const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache()
});

const ApolloClientRVI = ({ children, setLoading: fn }) => {
    setLoading = fn
    return <ApolloProvider client={client} >
        {children}
    </ApolloProvider>
}

export default ApolloClientRVI;