import React from 'react'
import styled from 'styled-components'

const Card = ({ children, style }) => {
    return (
        <Wrapper style={style}>
            { children }
        </Wrapper>
    )
}

const Wrapper = styled.div`
    border-radius: 8px;
    border: 1px solid #f0f0f0;
    padding: 1em 1.5em; 
    background-color: #fcfcfc;
    box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.1);
`

export default Card