import React from 'react'
import Button from "../../libraryComponent/Button/Button";
import { Input } from 'antd'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import styled from 'styled-components'

const TableSearch = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    dataIndex,
    reference
}) => {
    return (
        <div style={{ 
            padding: 8,
            borderRadius: 10,
            filter: 'saturate(0)'
        }}>
            <Input
                ref={reference}
                placeholder={`Buscar ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => confirm()}
                style={{ 
                    width: 188, 
                    marginBottom: 8, 
                    display: 'block',
                }}
            />
            <ButtonWrapper>
                <Button
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{
                        width: 90, 
                        marginRight: 8 
                    }}
                >
                    Buscar
                </Button>
                <Button 
                    onClick={() => clearFilters()} 
                    icon={<CloseOutlined />}
                    size="small" 
                    type="grey"
                    style={{ width: 90 }}
                >
                    Borrar
                </Button>
            </ButtonWrapper>
        </div>
    )
}

const ButtonWrapper = styled.div`
    display: flex;
`

export default TableSearch