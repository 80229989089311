import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { get } from 'lodash'
import { Container, Input, Button } from '../../libraryComponent'
import { message } from 'antd'

const ResetPassword = ({ match, history }) => {
    const [loading, setLoading] = useState(false)
    const [password, setPassword] = useState("")
    const [passwordConfirmation, setPasswordConfirmation] = useState("")
    const [error, setError] = useState(false)
    const [errorText, setErrorText] = useState("")
    const pwInput1 = useRef(null)
    const pwInput2 = useRef(null)
    const code = get(match, "params.code")

    const handleResetPw = ({ password, passwordConfirmation }) => {
        if(!password){
            setError(true)
            setErrorText("La contraseña no puede estar en blanco")
        }
        else if(password !== passwordConfirmation){
            setError(true)
            setErrorText("Las contraseñas no coinciden")
        }
        else if(password.length < 6){
            setError(true)
            setErrorText("La contraseña ha de tener al menos 6 caracteres")
        }
        else{
            setError(false)
            setLoading(true)
            fetch(process.env.REACT_APP_API_URL + "/auth/reset-password", {
                method: "POST",
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ password, passwordConfirmation, code })
            }).then(async resp => {
                if(resp.status === 200){
                    message.success("La contraseña se ha cambiado con éxito. Puedes acceder a RVI con tu nueva contraseña", 5)
                    history.push("/auth/login")
                }
                else{
                    const data = await resp.json()
                    const msg = get(data, "message[0].messages[0].id", "")
                    if(msg === "Auth.form.error.code.provide"){
                        setError(true)
                        message.error("El enlace de recuperación de contraseña es incorrecto o ha expirado", 5)
                    }
                    console.log({ data, msg })
                }
                setLoading(false)
            }).catch(err => {
                console.log({ err })
                message.error("Ocurrió un error durante el proceso de cambio de contraseña", 5)
                setLoading(false)
            })
        }
    }

    useEffect(() => {
        const fn = e => {
            if(e.key === "Enter"){
                handleResetPw({ 
                    password: get(pwInput1, "current.props.value", ""),
                    passwordConfirmation: get(pwInput2, "current.props.value", "")
                })
            }
        }
        window.addEventListener("keydown", e => { fn(e) } )
        return window.removeEventListener("keydown", e => { fn(e) } )
    }, [])

    return (
        <Container>
            <h1>Cambiar contraseña</h1>
            <Input ref={pwInput1} type="password" labelText="Nueva contraseña" isError={error} onChange={e => setPassword(e.target.value)} value={password} />
            <Input ref={pwInput2} type="password" labelText="Confirmar contraseña" isError={error} onChange={e => setPasswordConfirmation(e.target.value)} errorText={errorText} value={passwordConfirmation} />
            <br />
            <Button type="green" onClick={() => handleResetPw({ password, passwordConfirmation })}>Cambiar contraseña</Button>
        </Container>
    )
}

export default withRouter(ResetPassword)