import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "./App.css";
import { AppRouter, ApolloClient } from "./component";
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';
import LoadingContext from './context/LoadingContext'
import momentLocale from 'moment/locale/es';
import UploadSelectorContext from "./context/UploadSelectorContext";
import FinalContext from "./context/FinalContext";
import { CopiedConditionsContext } from "./context";

const App = ({ }) => {

  const [loadingCtx, setLoadingCtx] = useState({
    value: false,
    where: undefined,
    setLoading: (bool, where) => {
      // console.log({ bool, where })
      setLoadingCtx({
        ...loadingCtx,
        value: bool,
        where
      })
    }
  })

  const [uploadSelectorCtx, setUploadSelectorCtx] = useState({
    value: null,
    setValue: value => {
      setUploadSelectorCtx({
        ...uploadSelectorCtx,
        value
      })
    }
  })

  const [finalCtx, setFinalCtx] = useState({
    value: null,
    setValue: value => {
      setFinalCtx({
        ...finalCtx,
        value
      })
    }
  })

  const [copiedConditionsCtx, setCopiedConditionsCtx] = useState({
    value: null,
    setValue: value => {
      setCopiedConditionsCtx({
        ...copiedConditionsCtx,
        value
      })
    }
  })

  return (
    <ConfigProvider locale={esES}>
      <CopiedConditionsContext.Provider value={{
        value: copiedConditionsCtx.value,
        setValue: copiedConditionsCtx.setValue
      }}>
        <LoadingContext.Provider value={{
          loading: loadingCtx.value,
          setLoading: loadingCtx.setLoading
        }}>
          <UploadSelectorContext.Provider value={{
            value: uploadSelectorCtx.value,
            setValue: uploadSelectorCtx.setValue
          }}>
            <FinalContext.Provider value={{
              value: finalCtx.value,
              setValue: finalCtx.setValue
            }} >
              <ApolloClient setLoading={loadingCtx.setLoading}>
                <AppRouter />
              </ApolloClient>
            </FinalContext.Provider>
          </UploadSelectorContext.Provider>
        </LoadingContext.Provider >
      </CopiedConditionsContext.Provider>
    </ConfigProvider >
  )
};

export default App;
