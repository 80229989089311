import React, { useState, useEffect } from 'react';
import { Modal, Button, message, Skeleton } from 'antd'
import { Input } from '../../libraryComponent';
import { Input as InputAntd } from 'antd';
import styled from 'styled-components'
import { useMutation, useQuery } from '@apollo/react-hooks';
import { GET_USERS_JUST_UID } from '../../graphql/queries';
import { UPDATE_RESULT_MODEL } from '../../graphql/mutations';
import { currency } from '../../utils/constantes';

const ModalCreateValidation = ({ visible, setVisible, result_model, user, refetch, canModify }) => {
    const [correction, setCorrection] = useState({
        value: "",
        note: "",
        user: { id: user.id, name: user.name, lastname: user.lastname }
    });
    const [corrections, setCorrections] = useState([])
    const { data: dataUsers } = useQuery(GET_USERS_JUST_UID)
    const [updateResultModel] = useMutation(UPDATE_RESULT_MODEL, {
        variables: {
            id: result_model.id,
            corrections: [...result_model.corrections, correction]
        },
        onCompleted: async () => {
            await refetch()
            message.success("Modificacion correcta")
            setVisible(false)
            setCorrection({
                ...correction, value: "",
                note: "",
            })
        }
    });

    const modifyRVI = () => {
        if (correction.value < 0) {
            message.warning("El valor de RVI ha de ser mayor o igual a 0")
        }
        else {
            updateResultModel()
        }
    }

    useEffect(() => {
        if (dataUsers && Array.isArray(result_model.corrections)) {
            setCorrections(result_model.corrections.map(c => {
                const user = dataUsers.users.find(u => u.id === c.user.id)
                return ({
                    value: c.value + currency,
                    note: c.note,
                    user: user && user.name + " " + user.lastname
                })
            }))
        }
    }, [dataUsers, result_model])

    return (
        <Modal
            visible={visible}
            onCancel={() => setVisible(false)}
            closable={true}
            footer={<WrapperButton>
                <Button type="primary" onClick={() => modifyRVI()}>Modificar RVI</Button>
                <Button type="danger" onClick={() => setVisible(false)}>Cancelar</Button>
            </WrapperButton>}
        >
            <Wrapper>
                <Title>Historial de modificaciones</Title>
                {corrections.length > 0 ? (
                    <>
                        {corrections.map((c, i) => (
                            <Correction key={"c-" + i}>
                                <div>
                                    <span className="user">{c.user}: </span>
                                    <span className="value">{parseFloat(c.value) > 0 ? "+" : ""}{c.value}</span>
                                </div>
                                <span className="note">{c.note}</span>
                            </Correction>
                        ))}
                        <br />
                    </>
                ) : <Skeleton active="true" />}

                <>
                    <Title>Modificar RVI</Title>
                    <Input labelText="Modificacion" type="number" value={correction.value} onChange={e => setCorrection({ ...correction, value: e.target.value })} suffix={currency} style={{ width: "100%" }}></Input>
                    <br />
                    <Label>Nota</Label>
                    <InputAntd.TextArea value={correction.note} style={{ borderRadius: 14 }} onChange={e => setCorrection({ ...correction, note: e.target.value })}></InputAntd.TextArea>
                </>
            </Wrapper>
        </Modal>
    )
}


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`
const WrapperButton = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    justify-content: space-evenly;
`
const Label = styled.label`
    font-size: 1.1em;
    margin-bottom: 8px;
    width: 100%;
`
const Title = styled.h3`
    width: 100%;
    text-align: left;
    font-weight: bold;
`
const Correction = styled.div`
    width: 100%;
    padding: 6px;
    background-color: #f3f3f3;
    border-radius: 6px;
    margin-bottom: 0.5em;
    .user{
        font-weight: bold;
    }

`

export default ModalCreateValidation;