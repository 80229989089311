import React from 'react';
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const NavItemRVI = ({ label, to }) => {
    const Item = () => (
        <div>
            <span style={{ whiteSpace: "nowrap" }}>{label}</span>
            <div className="colorline" style={{
                "height": 3,
                "background": "linear-gradient(90deg, rgba(126,176,91,1) 0%, rgba(13,110,92,1) 100%)",
            }} />
        </div>   
    )
    if(to){
        if(typeof to === "string" && to.includes("http")){
            return (
                <ItemWrapper>
                    <div className="active">
                        <a href={to}>
                            <Item />
                        </a>
                    </div>
                </ItemWrapper>
            )
        }
        else{
            return (
                <ItemWrapper>
                    <Link to={to}>
                        <Item />
                    </Link>
                </ItemWrapper>
            )
        }
    }
    else{
        return (
            <ItemWrapper>
                <Item />
            </ItemWrapper>
        )
    }
}

const ItemWrapper = styled.div`
    padding: 10px;
    &:last-child{
        margin-right: 10px;
    }
    a{
        color: #666;
        font-size: 15px;
        font-weight: 600;
    }
`

export default NavItemRVI;