import React from 'react';
import { useLocation } from "react-router-dom";
import { UploadSelector, FinalSelector, Container } from '../../libraryComponent';
import styled from 'styled-components'
import { CopiedConditions } from '..';

const FilterContext = ({ }) => {
    let location = useLocation();
    return <OuterWrapper>
        <Container>
            <Wrapper>
                {(["/", "/hierarchy", "/result"].includes(location.pathname) || location.pathname.includes("/team")) && <UploadSelector />}
                {(["/", "/hierarchy", "/result"].includes(location.pathname) || location.pathname.includes("/team")) && <FinalSelector />}
                <CopiedConditions />
            </Wrapper>
        </Container>
    </OuterWrapper>
}

const OuterWrapper = styled.div`
    // margin-bottom: 2em;
    // border-bottom: 1px solid #f0f0f0;
    // box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);
`
const Wrapper = styled.div`
    margin-bottom: 1em;
    display: flex;
    flex-direction: row;
    & > div:not(last-child){
        margin-right: 1em;
    }
`

export default FilterContext;