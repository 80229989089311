import React, { useState } from 'react'
import TableFilter from '../libraryComponent/TableFilter/TableFilter'
import { FilterOutlined } from '@ant-design/icons'

const generateTableFilterProps = ({ dataIndex, dataSource }) => {
    const options = Array.isArray(dataSource) ? [...new Set(dataSource.map(row => row[dataIndex]))].filter(e => e) : []
    return ({
        filters: options.map(op => ({
            text: op,
            value: op
        })),
        onFilter: (value, record) => {
            if(record[dataIndex] === value){ return true }
        },
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, filters }) => {
            return (
                <TableFilter 
                    filters={filters}
                    setSelectedKeys={setSelectedKeys} 
                    selectedKeys={selectedKeys} 
                    confirm={confirm} 
                    clearFilters={clearFilters} 
                />
            )
        },
        filterIcon: bool => {
            return <FilterOutlined style={{ color: bool ? "#232323" : "#bfbfbf" }} />
        }
    })
}

export default generateTableFilterProps