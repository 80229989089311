import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const TeamCard = ({ team, model, hideManagers }) => {
    const managers = Array.isArray(team.managers) ? team.managers : []
    const managerStr = managers.reduce((acc, name, i, arr) => {
        const separator = i === 0 ? "" : (i + 1 === arr.length ? " y " : ", ")
        return acc + separator + name.name + " " + name.lastname
    }, "")
    const image = team.image ? process.env.REACT_APP_API_URL + team.image : (process.env.PUBLIC_URL + "/group_placeholder.jpg")
    return (
        <Link to={"/team/" + team.id}>
            <OuterWrapper>
                <img src={image} />
                <InnerWrapper>
                    <h2>{ team.name }</h2>
                    <h4><strong>Nivel:</strong> {team.level}</h4>
                    {!hideManagers && (managerStr  ? <h4><strong>Manager{managers.length > 1 ? "s" : ""}:</strong> { managerStr }</h4> : <h4>Manager no asignado</h4>)}
                </InnerWrapper>
            </OuterWrapper>
        </Link>
    )
}

const OuterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 220px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 1px 1px 4px 2px rgba(0,0,0,0.2);
    cursor: pointer;
    transition: ease-in 250ms;
    h2{
        margin: 0;
        font-size: 1.4em;
        line-height: 1.4em;
    }
    h4{
        margin-bottom: 0;
    }
    img{
        height: 100px;
        width: 100%;
        object-fit: cover;
        object-position: center center;
    }
    &:hover{
        transform: scale(1.05)
    }
`

const InnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-evenly;
    padding: 0 10px;
`

export default TeamCard