import React, { forwardRef } from "react";
import { Button } from "antd";
import styled from 'styled-components'
import { isFunction } from 'lodash'

const RVIButton = forwardRef(({ className, onClick, label, children, type = "white", ...rest }, ref) => {
  const innerBtn = label || children
  return (
    <Wrapper type={type}>
      <Button className={className} onClick={() => isFunction(onClick) ? onClick() : undefined} {...rest}>
        {innerBtn}
      </Button>
    </Wrapper>
  );
});

const gradients = {
  green: "linear-gradient(135deg, rgba(80,150,127,1) 0%, rgba(40,126,109,1) 100%);",
  yellow: "linear-gradient(135deg, rgba(249,190,80,1) 0%, rgba(248,147,53,1) 100%);",
  red: "linear-gradient(135deg, rgba(228,66,82,1) 0%, rgba(175,19,40,1) 100%);",
  blue: "linear-gradient(135deg, rgba(23,100,178,1) 0%, rgba(8,195,222,1) 100%);",
  grey: "linear-gradient(135deg, rgba(134,134,134,1) 0%, rgba(126,126,126,1) 100%);",
  white: "white"
}

const Wrapper = styled.div`
  .ant-btn{
    border-radius: 6px;
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.2);
    border: none;
    opacity: 1;
    transition: all 200ms ease-in;
    background: ${({type}) => gradients[type]};
    color: ${({type}) => type === "white" ? "rgba(0, 0, 0, 0.8)" : "white"}
  }
  .ant-btn:hover{
    opacity: 0.8;
    color: ${({type}) => type === "white" ? "rgba(0, 0, 0, 0.8)" : "white"};
    background: ${({type}) => type === "white" ? "#f3f3f3" : gradients[type]};
  }
`

export default RVIButton;
