import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { SingleCondition, ModalConfirmModel, PrivateComponent } from '../../component';
import { Container, Button, Card, TableLabel } from '../../libraryComponent';
import { useLocation, withRouter } from "react-router-dom";
import { GET_MODEL, GET_MODELS } from '../../graphql/queries';
import { UPDATE_MODEL } from '../../graphql/mutations';
import { useQuery, useMutation } from '@apollo/react-hooks';
import _, { get, uniqBy } from 'lodash';
import { message } from 'antd';
import styled from 'styled-components'
import { CopiedConditionsContext } from '../../context';
import { kidTeoricos, kidBase } from '../../utils/constantes';

const Conditionals = ({ history }) => {
    let location = useLocation();

    const [visible, setVisible] = useState(false);
    const [recalc, setRecalc] = useState(false)
    const { value: valueCCC } = useContext(CopiedConditionsContext)
    const model = new URLSearchParams(location.search).get("model")
    const { data, error } = useQuery(GET_MODEL, { variables: { model } })
    const [updateModel] = useMutation(UPDATE_MODEL, {
        onCompleted: () => {
            history.push("/")
        },
        refetchQueries: [
            {
                query: GET_MODEL,
                variables: { model }
            },
            {
                query: GET_MODELS,
                variables: { uploadrvi: data && data.model.uploadrvi.id, final: data && data.model.final }
            }
        ]
    });
    const emptyCondition = {
        select1: null,
        arrayKpisSi: [],
        arrayConditionsSi: null,
        intSi: 0,
        select5: null,
        arrayKpisEntonces: [],
        intEntonces: 0,
        intEntoncesMode: "%"
    }
    const [conditionals, setConditionals] = useState([]);
    const [kpiList, setKpiList] = useState([])

    const addCondition = () => setConditionals([...conditionals, emptyCondition])

    useEffect(() => {
        if (data) {
            if (data.model.conditions && data.model.conditions.length !== 0) {
                setConditionals(data.model.conditions)
            }
            if (data.model.ready) { setRecalc(true) }
            setKpiList(uniqBy(get(data, "model.kpis", []), "kid"))
            setKpiList([
                ...uniqBy(get(data, "model.kpis", []).filter(x => x.type === "INDIVIDUAL"), "kid"),
                ...uniqBy(get(data, "model.kpis", []).filter(x => x.type === "COLLECTIVE"), "kid"),
                ...uniqBy(get(data, "model.kpis", []).filter(x => x.type === "PENALIZER" && x.kid !== kidBase && x.kid !== kidTeoricos), "kid"),
                ...uniqBy(get(data, "model.kpis", []).filter(x => x.type === "PENALIZER" && x.kid === kidBase || x.kid === kidTeoricos).map(x => ({ ...x, type: "GLOBAL" })), "kid"),
                ...uniqBy(get(data, "model.kpis", []).filter(x => x.type === "BONUS"), "kid")
            ])
        }
    }, [data])

    const calc = async () => {

        if (recalc) {
            const resultProm = get(data, "model.result_models", []).map(result_model => {
                return fetch(process.env.REACT_APP_API_URL + "/result-models/" + result_model.id, {
                    method: "DELETE",
                    headers: {
                        Accept: "multipart/form-data",
                        Authorization: `Bearer ${localStorage.getItem("jwt")}`
                    }
                })
            })
            const resultResp = await Promise.all(resultProm)
            // console.log({ resultResp })
        }
        const updateRes = await updateModel({
            variables: {
                id: model,
                conditions: conditionals,
                ready: true
            }
        })
        // console.log({ updateRes })
        message.success((recalc ? "Rec" : "C") + "alculando los RVIs del modelo " + get(data, "model.name") + ", en breves dispondra de todos los resultados.")
    }

    return (
        <Container>
            <Link to="/">&lt; Volver</Link>
            <ModalConfirmModel
                visible={visible}
                setVisible={visible => setVisible(visible)}
                modelName={get(data, "model.name")}
                recalcFlag={recalc}
                calc={calc}
                nocalc={() => updateModel({ variables: { id: model, conditions: conditionals, ready: false } }).then(() => message.success("Condiciones creadas correctamente para el modelo " + get(data, "model.name")))}
            />
            <h1>Condiciones del modelo {get(data, "model.name")}</h1>

            {valueCCC && <Button style={{ marginBottom: "2em" }} onClick={() => {
                const fixed = valueCCC.conditions && valueCCC.conditions.length > 0 ? valueCCC.conditions.map(x => {
                    const { select1, select5, arrayKpisSi, arrayKpisEntonces, specialInt } = x;
                    const arrayKpisSiRes = arrayKpisSi.filter(y => kpiList.some(z => z.type === select1 && z.kid === y) || specialInt)
                    const arrayKpisEntoncesRes = arrayKpisEntonces.filter(y => kpiList.some(z => z.type === select5 && z.kid === y) || y === "RVI")

                    const kid1 = arrayKpisSi.map(y => {
                        const res = y === "SPECIAL" ? y : kpiList.find(z => z.type === select1 && z.kid === y);
                        return res ? res : 0;
                    })


                    const kid2 = arrayKpisEntonces.map(y => {
                        const res = y === "RVI" ? y : kpiList.find(z => z.type === select5 && z.kid === y);
                        return res ? res : 0;
                    })

                    if (kid1.some(x => x === 0) || kid2.some(x => x === 0)) {
                        message.warning("Alguno de los kpis importados no coincide con este modelo. Se obviaran estos kpis")
                    }

                    return { ...x, arrayKpisSi: arrayKpisSiRes, arrayKpisEntonces: arrayKpisEntoncesRes }
                }) : []

                setConditionals([...fixed, ...conditionals])
            }}><TableLabel>Pegar aqui las condiciones del modelo copiado</TableLabel></Button>}

            {(Array.isArray(conditionals) && conditionals.length > 0) ? conditionals.map((condition, i) => {
                return <SingleCondition key={"condition-" + i} ready={data && data.model.ready} condition={condition} conditionals={conditionals} setConditionals={setConditionals} kpiList={kpiList} i={i} />
            }) : (
                    <Card style={{ marginBottom: "2em" }}>
                        <h3 style={{ margin: 0 }}>No hay ninguna condición</h3>
                    </Card>
                )}

            <PrivateComponent allowed={["admin"]}>
                <ButtonWrapper>
                    <Button type="grey" onClick={() => {
                        if (conditionals[conditionals.length - 1]) {
                            const error = (conditionals[conditionals.length - 1].select1 === null) ||
                                (conditionals[conditionals.length - 1].arrayKpisSi.length === 0) ||
                                (conditionals[conditionals.length - 1].arrayConditionsSi === null) ||
                                (conditionals[conditionals.length - 1].intSi === 0) ||
                                (conditionals[conditionals.length - 1].select5 === null) ||
                                (conditionals[conditionals.length - 1].arrayKpisEntonces.length === 0) ||
                                (conditionals[conditionals.length - 1].intEntonces === 0) ? true : false
                            error ? message.error("Debes completar las condiciones antes de añadir una nueva.") : addCondition()
                        } else {
                            addCondition()
                        }
                    }}>Añadir condición</Button>
                    <Button type="green" onClick={() => {
                        conditionals.length !== 0 && conditionals[conditionals.length - 1].intEntonces === 0 ? message.error("Condiciones incompletas") : setVisible(true)
                    }}>Guardar condiciones del modelo</Button>
                </ButtonWrapper>
            </PrivateComponent>

            <br />
        </Container >
    )
}

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    & > div:not(:last-child){
        margin-right: 1em;
    }
`

export default withRouter(Conditionals);
