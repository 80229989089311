import XLSX from 'xlsx';
import _ from 'lodash';
import { message } from 'antd'
import moment from 'moment';


export const uploadModel = async (record, provisional, fileUpload, createModel, setLoading, updateWorkday) => {
    await setLoading(0.1)
    return new Promise((resolve, reject) => {
        // console.log(fileUpload)
        let file = fileUpload, read = new FileReader();
        const formDate = moment(record.date_start).format("YYYYMM")

        const headers = {
            Accept: "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`
        }

        read.readAsBinaryString(file)
        read.onloadend = async () => {
            try {
                var oFile = XLSX.read(read.result, { type: 'binary' })
                const data = XLSX.utils.sheet_to_json(oFile.Sheets[oFile.SheetNames[0]], { cellNF: true, raw: false })

                // Primera comprobacion columna FECHA sea única y coincida con la introducida en el formulario.
                const uniqDate = _.uniq(data.map(x => x.FECHA))
                const uniqUsers = _.uniq(data.map(x => x.UID)).map(async x => {
                    const response = await fetch(process.env.REACT_APP_API_URL + "/users/UID/" + x, {
                        method: "GET",
                        headers
                    }).then(data => data.json()).catch(e => console.log(e))
                    return response
                })
                const findUsers = await Promise.all(uniqUsers)
                // if (findUsers.some(x => x.statusCode)) {
                //     throw ({ message: "UIDs no validos en el fichero." });
                // }
                if (uniqDate.length === 1) {
                    if (formDate === uniqDate[0]) {
                        //modelList es el array que luego se recorrera para hacer la subida de datos de los modelos [{Modelo,Direccion,Rol,ACTIVIDAD,kpis}]
                        let modelList = _.uniqBy(data.map(x => ({ name: x.Modelo, direction: x.Direccion, role: x.Rol, activity: x.ACTIVIDAD, final: !provisional, kpiJSON: [], uploadrvi: record.id, slugupload: record.id + "" })), "name")
                        // comenzamos a recorrer filas del xlsx y introducimos los kpis del modelo
                        data.forEach(row => {
                            let kpi = {};
                            // console.log(findUsers)
                            const actualModelIndex = modelList.findIndex(x => x.name === row.Modelo);
                            const findUser = findUsers.find(x => x.UID === row.UID)

                            if (findUser) {
                                if (row['DESC KPI'].includes("Base RVI")) {
                                    const workday = findUser.workdays.filter(workday => workday.uploadrvi === record.id)
                                    if (workday.length > 0) {
                                        // hacemos el update del workday
                                        const variables = {
                                            id: workday[0].id,
                                            base: parseFloat(row.RESULTADO)
                                        }
                                        updateWorkday({ variables })
                                    }
                                }
                                // Aqui tendre que coger la base de ese UID y hacer un update al workday
                                kpi.user = findUser.id;
                                kpi.type = row['INDIV o COLECT'] === "1" ? "INDIVIDUAL" : row['INDIV o COLECT'] === "2" ? "COLLECTIVE" : (row.RESULTADO === "NO" || row.RESULTADO === "SI" || row.OBJETIVO === "NO" || row.OBJETIVO === "SI") ? "BONUS" : "PENALIZER";
                                kpi.kid = row['INDIV o COLECT'] + row['PPAL o SECUND (solo si OBJ INDV)'] + row.KPI;
                                kpi.name = row['DESC KPI'];
                                kpi.inverse = row['DESC KPI'].includes("(INV)");
                                if (row['INDIV o COLECT'] !== "0") {
                                    kpi.objetive = parseFloat(row.OBJETIVO + row.RESULTADO);
                                } else {
                                    kpi.objetive = row.RESULTADO === "NO" || row.OBJETIVO === "NO" ? 0 : row.RESULTADO === "SI" || row.OBJETIVO === "SI" ? 1 : parseFloat(row.RESULTADO + row.OBJETIVO);
                                    kpi.achievement = row.RESULTADO === "NO" || row.OBJETIVO === "NO" ? 0 : row.RESULTADO === "SI" || row.OBJETIVO === "SI" ? 1 : parseFloat(row.RESULTADO + row.OBJETIVO);
                                }
                                if (!provisional) {
                                    if (row['INDIV o COLECT'] !== "0") {
                                        kpi.resultkpi = parseFloat(row.RESULTADO);
                                    }
                                }
                                kpi.weight = parseFloat(row["PONDERACIÓN"])
                                modelList[actualModelIndex] = { ...modelList[actualModelIndex], kpiJSON: [...modelList[actualModelIndex].kpiJSON, kpi] }
                            }
                        })

                        let createdModel = []
                        for await (const model of modelList) {
                            await createModel({
                                variables: {
                                    ...model
                                }
                            })
                            createdModel.push(model)
                            setLoading(10 + (createdModel.length / modelList.length) * 90)
                        }

                        setLoading(false)
                        message.success("Fichero subido correctamente, en breves dispondra de los modelos con los kpis", 15)
                        resolve({ ok: true })

                    } else {
                        throw ({ message: "La FECHA introducida no coincide con la seleccionada." });
                    }
                } else {
                    throw ({ message: "Columna FECHA no es única." });
                }

            } catch (err) {
                setLoading(false)
                if (err.message === "File is password-protected") {
                    message.error("El archivo está protegido con contraseña. Por favor, desbloquéalo antes de subirlos.", 15)
                }
                else {
                    message.error(`Se ha producido un error al leer el archivo (${err.message})`, 15)
                }
                resolve({ ok: false })

            }
        }
    })
}