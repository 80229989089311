import React from 'react'
import { Container } from '../../libraryComponent'

const ErrorPage = () => {
    return (
        <Container>
            <h1>Error!</h1>
            <p>Parece que algo salió mal</p>
        </Container>
    )
}

export default ErrorPage