import React from 'react'
import { withRouter, Link } from 'react-router-dom'
import { NavItem } from '../../libraryComponent'
import { PrivateComponent } from '..'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'

const Header = ({ location, items, logo, children }) => {
    return (
        <Wrapper>
            <UpperWrapper>
                <Link to="/">
                    <Logo src={logo} alt="RVI Logo" align="left" />
                </Link>
                {!location.pathname.includes("/auth") && <LinkWrapper>
                    {
                        items.map((it, i) => {
                            const active = typeof it.to === "string" && (it.to.split("/")[1] === location.pathname.split("/")[1] || Array.isArray(it.also) && !!it.also.find(al => location.pathname.split("/")[1].includes(al)))
                            return (
                                <PrivateComponent allowed={it.allowed} key={"link-" + i}>
                                    <ItemWrapper>
                                        <CSSTransition
                                            in={active}
                                            timeout={{ enter: 300, exit: 300 }}
                                            classNames={'line'}
                                        >
                                            <div className={active ? "active" : ""}>
                                                <NavItem label={it.label} to={it.to} />
                                            </div>
                                        </CSSTransition>
                                    </ItemWrapper>
                                </PrivateComponent>
                            )
                        })
                    }
                </LinkWrapper>}
            </UpperWrapper>
            {children}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    margin-bottom: 2em;
    border-bottom: 1px solid #f0f0f0;
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.03);
    justify-content: space-between;
    a{
        height: 100%;
    }
`
const UpperWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100px;
    width: 100%;
`
const Logo = styled.img`
    height: 100%;
    object-fit: contain;
    object-position: 0%;
    padding: 1em;
`
const LinkWrapper = styled.div`
    display: flex;
`
const ItemWrapper = styled.div`
    width: 100%;
    .colorline{
        width: 0%
    }
    .active .colorline{
        width: 100%;
    }
    .line-enter .colorline{
        width: 0%;
      }
    .line-enter-active .colorline{
        width: 100%;
        transition: width 300ms;
    }
    .line-exit .colorline{
        width: 100%;
    }
    .line-exit-active .colorline{
        width: 0%;
        transition: width 300ms;
    }
`
export default withRouter(Header)