import React from 'react'
import styled from 'styled-components';
import { gradients } from '../../utils/gradients'

const BoxListGoals = ({ data = { title: "Ojetivos Individuales", data: [] } }) => {
    return (
        <BoxGoals >
            <Title>
                <span>{data.title}</span>
            </Title>

            {
                data.data && data.data.length > 0 && data.data.map(item => {
                    return (<ItemIzq key={item.kid}>
                        <span>{item.name}</span>
                    </ItemIzq>)
                })
            }
        </BoxGoals>
    )
}

const BoxGoals = styled.div`
    font-weight: bold;
    color: rgba(80,150,127,1);
    border-radius: 6px;
    box-shadow: 0 0 10px 0 #a8a8a8;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 90%;
`

const ItemIzq = styled.div`
    background: ${gradients.grey};
    color: white;
    margin: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.2)
`

const Title = styled.div`
    color: black;
    font-size: large;
    display: flex;
    justify-content: center;
    padding-top: 0.5em;
`

export default BoxListGoals