import React from 'react'
import { get } from 'lodash'

const PrivateComponent = ({ children, allowed = [] }) => {
    let user
    try{
        user = JSON.parse(localStorage.getItem("user"))
    }
    catch(err){
        user = { role: { type: "unauthorised" } } 
    }
    const role = get(user, "role.type")
    if(allowed.includes(role)){
        return children
    }
    else{
        return null
    }
}

export default PrivateComponent