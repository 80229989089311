import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { message } from 'antd'
import { Container, Input, Button } from '../../libraryComponent'
import styled from 'styled-components'
import { get } from 'lodash'

const ForgotPassword = () => {
    const [error, setError] = useState(false)
    const [email, setEmail] = useState("")
    const [txt, setTxt] = useState("")
    const [loading, setLoading] = useState(false)
    const emailInput = useRef(null)
    const handleRecover = email => {
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL + "/auth/forgot-password", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ email })
        }).then(async resp => {
            console.log({ resp })
            if(resp.status === 200){
                setError(false)
                setTxt("")
                message.success("En breves recibirás un correo electrónico en la dirección introducida para que puedas recuperar tu contraseña", 5)
            }
            else{
                const data = await resp.json()
                const msg = get(data, "message[0].messages[0].id", "")
                setError(true)
                if(msg === "Auth.form.error.email.format"){ setTxt("El formato del email es incorrecto") }
                else if(msg === "Auth.form.error.user.not-exist"){ setTxt("No existe ningún usuario con ese correo electrónico") }
                else{ setTxt("Ocurrió un error durante el proceso")}
                console.log({ data })
            }
            setLoading(false)
        }).catch(err => {
            console.log({ err })
            setError(true)
            setTxt("Ocurrió un error durante el proceso")
            setLoading(false)
        })
    }

    useEffect(() => {
        const fn = e => {
            if(e.key === "Enter"){
                handleRecover(get(emailInput, "current.props.value", ""))
            }
        }
        window.addEventListener("keydown", e => { fn(e) } )
        return window.removeEventListener("keydown", e => { fn(e) } )
    }, [])

    return (
        <Container>
            <h1>Recuperación de contraseña</h1>
            <Input ref={emailInput} labelText="Email" isError={error} errorText={txt} value={email} onChange={e => setEmail(e.target.value)} />
            <Wrapper>
                <Button type="green" loading={loading} onClick={() => handleRecover(email)}>Recuperar contraseña</Button>
                <Forgot><Link to="/auth/login">Volver al login</Link></Forgot>
            </Wrapper>
        </Container>
    )
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.5em;
`
const Forgot = styled.div`
    margin-top: 1em;
    a{
        color: #5a5a5a;
        font-weight: bold;
        cursor: pointer;
    }
    a:hover{
        color: #777;
    }
`

export default ForgotPassword            