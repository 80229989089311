import React, { useEffect, useState, useRef } from 'react';
import { Modal, Button } from 'antd';
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Input } from '../../libraryComponent';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_PASSWORD } from '../../graphql/mutations';

const ChangePass = ({ history }) => {
    const [form, setForm] = useState({
        pass1: "",
        pass2: ""
    })
    const [error, setError] = useState({
        isError: false,
        errorText: ""
    })

    const [updatePassword] = useMutation(UPDATE_PASSWORD);

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem("user"));
        !user && history.push("/auth/local");
        user?.changePass && history.push("");
    }, [])

    return <Modal
        visible={true}
        closable={false}
        footer={<WrapperButton>
            <Button type="primary" onClick={() => {
                if (form.pass1.trim() !== "" && form.pass2.trim() !== "" && form.pass1 === form.pass2) {
                    const user = JSON.parse(localStorage.getItem("user"));
                    updatePassword({
                        variables: {
                            id: user.id,
                            password: form.pass1,
                            changePass: true
                        }
                    }).then(() => {
                        localStorage.setItem("user", JSON.stringify({ ...JSON.parse(localStorage.getItem("user")), changePass: true }))
                        history.push("")
                    }).catch(e => setError({ isError: true, errorText: "Error. Intentelo de nuevo." }))
                } else {
                    setError({
                        isError: true,
                        errorText: "Las contraseñas no pueden estar vacias y deben coincidir."
                    })
                }
            }}>Cambiar Contraseña</Button>
        </WrapperButton>}
    >
        <Wrapper>
            <Title>Bienvenido a la plataforma RVI. Antes de continuar, por motivos de seguridad, es necesario cambiar su contraseña</Title>
            <Input labelText="Nueva contraseña" type="password" value={form.pass1} onChange={e => setForm({ ...form, pass1: e.target.value })} isError={error.isError} errorText={error.errorText}></Input>
            <Input labelText="Repita su contraseña nueva" type="password" value={form.pass2} onChange={e => setForm({ ...form, pass2: e.target.value })} isError={error.isError} errorText={error.errorText}></Input>
        </Wrapper>
    </Modal>
}

const WrapperButton = styled.div`
    display: flex;
    flex-direction: row;
    margin: 20px;
    justify-content: space-evenly;
`
const Title = styled.h3`
    width: 100%;
    text-align: left;
    font-weight: bold;
`
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

export default withRouter(ChangePass);