import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
import styled from 'styled-components'
import { Container, TableLabel, CardGlobalResult, CardSingleResult, CardSimple, Select } from '../../libraryComponent';
import { useQuery, useLazyQuery } from '@apollo/react-hooks'
import { Row, Col, message, DatePicker, Tooltip } from 'antd';
import { GET_MODELS_OF_USER } from '../../graphql/queries'
import { uniqBy, capitalize, get, flattenDeep } from 'lodash'
import { calculatervi } from '../../utils/calculatervi'
import { currency } from '../../utils/constantes'
import moment from 'moment'
import { EllipsisOutlined, CheckCircleTwoTone, ExclamationCircleTwoTone, EditTwoTone } from '@ant-design/icons'
import { Table, UserCard } from '../../component';

const ResultList = ({ match }) => {

    const user = JSON.parse(localStorage.user)
    const [ur, setUr] = useState([])
    const { data, loading, refetch, error } = useQuery(GET_MODELS_OF_USER, {
        variables: {
            user: match.params.userID || user.id
        }
    })

    const columns = [
        {
            title: "Mes",
            key: "name",
            align: "center",
            render: row => <TableLabel dark>{capitalize(moment(row.date_start).format("MMMM YYYY"))}</TableLabel>
        },
        {
            title: "Modelo",
            key: "model",
            render: row => <TableLabel>{ row.models.find(m => m.final) ? row.models.find(m => m.final).name : row.models[0].name }</TableLabel>
        },
        {
            title: "Estado",
            key: "state",
            render: row => <TableLabel>{row.models.some(m => m.final) ? "Final" : "Provisional"}</TableLabel>
        },
        {
            title: "Retribución",
            key: "retrib",
            render: row => <TableLabel>{ row.result_models[0] ? calculatervi(row.result_models[0]) + currency : "-" }</TableLabel>
        },
        {
            title: "Ver",
            key: "btn",
            render: row => {
                const rm = row.result_models[0]
                const getIcon = () => {
                    if (!rm) { return <Tooltip title="RVI no calculado"><EllipsisOutlined /></Tooltip> }
                    // else if (!canValidate(rm)) { return <Tooltip title="RVI calculado y validado"><CheckCircleTwoTone twoToneColor="#52c41a" /></Tooltip> }
                    else if (rm.corrections.length > 0) { return <Tooltip title="RVI corregido"><ExclamationCircleTwoTone twoToneColor="#faad14" /> </Tooltip> }
                    else { return <Tooltip title="RVI calculado, pendiente de validación"><EditTwoTone /></Tooltip> }
                }
                const model = row.models.find((m, i, arr) => m.final || arr.length === 1)
                if (get(model, "id")) {
                    return <Link to={`/result/${model.id}/${data.kpis[0].user.id}`}>{getIcon()}</Link>
                }
            }
        }
    ]

    useEffect(() => {
        if(data){
            const models = uniqBy(data.kpis.map(k => k.model), "id")
            const uploadrvis = uniqBy(models.map(m => m.uploadrvi), "id").sort((a, b) => {
                if(moment(a.date_start).isBefore(moment(b.date_start))){ return 1 }
                else{ return -1 }
            }).map(ur => {
                const modelsPerUpload = models.filter(m => m.uploadrvi.id === ur.id)
                return ({
                    ...ur,
                    models: modelsPerUpload,
                    result_models: get(data, "kpis[0].user.result_models", []).filter(rm => modelsPerUpload.map(m => m.id).includes(rm.model.id))
                })
            })
            setUr(uploadrvis)
        }
    }, [data])

    return (
        <Container>
            <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <UserCard
                    name={get(data, "kpis[0].user.name", "") + " " + get(data, "kpis[0].user.lastname", "")}
                    email={get(data, "kpis[0].user.email", "")}
                    team={get(data, "kpis[0].user.workerOf[0]", "")}
                    image="https://vietnamhairs.vn/wp-content/uploads/1068x534/suggested-hairstyle-for-girl-in-the-office-3.jpg"
                    style={{ height: 232, width: "60vw", minWidth: 280, maxWidth: 720, marginBottom: 20 }}
                />
            </div>
            <br />
            <h2>Lista de RVI</h2>
            <Table
                dataSource={ur}
                columns={columns}
                rowKey={"id"}
            />
        </Container>
    )
}

export default withRouter(ResultList)