import { gql } from '@apollo/client';

export const CREATE_MODEL = gql`
    mutation($name: String, $final: Boolean, $uploads: JSON, $direction: String, $role: String, $activity: String, $kpiJSON: JSON, $uploadrvi: ID , $slugupload: String){
        createModel(input: {
            data: {
                name: $name
                final: $final
                uploads: $uploads
                direction: $direction
                role: $role
                activity: $activity
                kpiJSON: $kpiJSON
                uploadrvi: $uploadrvi
                slugupload: $slugupload
            }
        }){
            model{
                id
            }
        }
    }
`;

export const LOGIN = gql`
    mutation($input: UsersPermissionsLoginInput!){
        login(input: $input){
            user {
                id
                email
                role {
                    type
                }
            }
            jwt
        }
    }
`

export const CREATE_UPLOADRVI = gql`
    mutation(
        $date_start: DateTime, 
        $date_end: DateTime,
        $files: JSON,
        $created_user: ID
    ){
        createUploadrvi(input: {
            data: {
                date_start: $date_start
                date_end: $date_end
                files: $files
                created_user: $created_user
            }
        }){
            uploadrvi{
                id
            }
        }
    }
`

export const UPDATE_UPLOADRVI = gql`
    mutation(
        $id: ID!,
        $files: JSON,
    ){
        updateUploadrvi(input: {
            where: {
                id: $id
            },
            data: {
                files: $files
            }
        }){
            uploadrvi{
                id
                files
            }
        }
    }
`

export const UPLOAD_FILE = gql`
    mutation($file: Upload!) {
        upload(file: $file) {
            id
            name
            createdAt
            url
        }
    }
`

export const CREATE_TEAM = gql`
    mutation(
       $name: String!,
       $teamID: String!, 
       $level: Int!,
       $slug: String,
       $fathers: [ID],
       $uploadrvi: ID!,
       $final: Boolean
    ){
        createTeam(input: {
            data: {
                name: $name
                teamID: $teamID
                level: $level
                slug: $slug
                fathers: $fathers
                uploadrvi: $uploadrvi
                final: $final
            }
        }){
            team{
                id
                teamID
                name
            }
        }
    }
`

export const DELETE_TEAM = gql`
    mutation(
        $id: ID!
    ){
        deleteTeam(input: {
            where: {
                id: $id
            }
        }){
            team{
                id
            }
        }
    }
`

export const DELETE_USER = gql`
    mutation(
        $id: ID!
    ){
        deleteUser(input: {
            where: {
                id: $id
            }
        }){
            user{
                id
            }
        }
    }
`

export const CREATE_USER = gql`
    mutation(
        $username: String!,
        $email: String!,
        $password: String!,
        $UID: String!,
        $name: String,
        $lastname: String,
        $role: ID,
        $managerOf: [ID],
        $workerOf: [ID],
        $uploadrvis_created: [ID],
        $kpis: [ID],
        $blocked: Boolean,
        $confirmed: Boolean
    ){
        createUser(input: {
            data: {
                username: $username
                email: $email
                password: $password
                UID: $UID
                name: $name
                lastname: $lastname
                role: $role
                managerOf: $managerOf
                workerOf: $workerOf
                uploadrvis_created: $uploadrvis_created
                kpis: $kpis
                blocked: $blocked
                confirmed: $confirmed
            }
        }){
            user{
                id
                UID
            }
        }
    }
`

export const UPDATE_PASSWORD = gql`
mutation($id: ID!, $changePass: Boolean, $password: String){
    updateUser(input: {
        where: {
            id: $id
        }
        data: {
            changePass: $changePass
            password: $password
        }
    }){
        user{
            id
        }
    }
}

`

export const UPDATE_USER = gql`
    mutation(
        $id: ID!,
        $username: String,
        $email: String,
        $password: String,
        $UID: String,
        $name: String,
        $lastname: String,
        $role: ID,
        $managerOf: [ID],
        $workerOf: [ID],
        $uploadrvis_created: [ID],
        $kpis: [ID],
        $blocked: Boolean,
        $confirmed: Boolean
    ){
        updateUser(input: {
            where: {
                id: $id
            }
            data: {
                username: $username
                email: $email
                password: $password
                UID: $UID
                name: $name
                lastname: $lastname
                role: $role
                managerOf: $managerOf
                workerOf: $workerOf
                uploadrvis_created: $uploadrvis_created
                kpis: $kpis
                blocked: $blocked
                confirmed: $confirmed
            }
        }){
            user{
                id
                UID
            }
        }
    }
`

export const CREATE_RESULT = gql`
mutation($kpi: ID, $amount: JSON){
    createResult(input: {
        data: {
            kpi: $kpi,
            amount: $amount
        }
    }){
        result{
            id
        }
    }
}
`


export const CREATE_WORKDAY = gql`
    mutation(
        $user: ID!,
        $uploadrvi: ID!,
        $workdayfraction: Float,
        $absences: Float
    ){
        createWorkday(input: {
            data: {
                user: $user
                uploadrvi: $uploadrvi
                workdayfraction: $workdayfraction
                absences: $absences
            }
        }){
            workday{
                id
            }
        }
    }
`

export const DELETE_WORKDAY = gql`
    mutation(
        $id: ID!
    ){
        deleteWorkday(input: {
            where: {
                id: $id
            }
        }){
            workday{
                id
            }
        }
    }
`

export const DELETE_MODEL = gql`
    mutation(
        $id: ID!
    ){
        deleteModel(input: {
            where: {
                id: $id
            }
        }){
            model{
                id
            }
        }
    }
`

export const DELETE_KPI = gql`
    mutation(
        $id: ID!
    ){
        deleteKpi(input: {
            where: {
                id: $id
            }
        }){
            kpi{
                id
            }
        }
    }
`

export const UPDATE_MODEL = gql`
    mutation(
        $id: ID!,
        $conditions: JSON
        $ready: Boolean
    ){
        updateModel(input: {
            where: {
                id: $id
            }
            data: {
                conditions: $conditions
                ready: $ready
            }
        }){
            model{
                id
            }
        }
    }
`

export const UPDATE_RESULT_MODEL = gql`
    mutation($id: ID!, $corrections: JSON ){
        updateResultModel(input: {
            where: {
                id: $id
            }
            data: {
                corrections: $corrections
            }
        }){
            resultModel{
                id
            }
        }
    }
`

export const UPDATE_WORKDAY = gql`
    mutation($id: ID!, $base: Float ){
        updateWorkday(input: {
            where: {
                id: $id
            }
            data: {
                base: $base
            }
        }){
            workday{
                id
            }
        }
    }
`

export const UPDATE_RESULT_MODEL_VALIDATE = gql`
    mutation($id: ID!, $validated: JSON ){
        updateResultModel(input: {
            where: {
                id: $id
            }
            data: {
                validated: $validated
            }
        }){
            resultModel{
                id
            }
        }
    }
`