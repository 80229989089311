import React from 'react'
import Button from "../../libraryComponent/Button/Button";
import styled from 'styled-components'
import { SearchOutlined, CloseOutlined } from '@ant-design/icons'
import CheckCell from '../CheckCell/CheckCell';

const TableFilter = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    filters
}) => {

    const handleCheck = clicked => {
        if(selectedKeys.includes(clicked)){
            setSelectedKeys(selectedKeys.filter(k => k !== clicked))
        }
        else{
            setSelectedKeys([...selectedKeys, clicked])
        }
    }
    return (
        <div style={{ 
            padding: 8,
            borderRadius: 10,
            filter: 'saturate(0)'
        }}>
            {
                filters.map(f => {
                    return (
                        <FilterRow key={f.value}>
                            <CheckCell checked={selectedKeys.includes(f.value)} onClick={() => handleCheck(f.value)}/>
                            <Label>{f.text}</Label>
                        </FilterRow>
                    )
                })
            }
            <ButtonWrapper>
                <Button
                    onClick={() => confirm()}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{
                        width: 90, 
                        marginRight: 8 
                    }}
                >
                    Buscar
                </Button>
                <Button 
                    onClick={() => clearFilters()} 
                    icon={<CloseOutlined />}
                    size="small" 
                    type="grey"
                    style={{ width: 90 }}
                >
                    Borrar
                </Button>
            </ButtonWrapper>
        </div>
    )
}

const FilterRow = styled.div`
    display: flex;
    height: 2em;
    margin-bottom: 5px;
    .innerCheck {
        top: -16px !important;
        left: -1px !important;
    }
` 
const Label = styled.div`
    padding-left: 30px;
    margin-top: -3px;
`
const ButtonWrapper = styled.div`
    display: flex;
`

export default TableFilter