import React from 'react'
import TableSearch from '../libraryComponent/TableSearch/TableSearch'
import { SearchOutlined } from '@ant-design/icons'

const generateTableSearchProps = ({ dataIndex, reference }) => {
    return ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <TableSearch 
                    setSelectedKeys={setSelectedKeys} 
                    selectedKeys={selectedKeys} 
                    confirm={confirm} 
                    clearFilters={clearFilters} 
                    dataIndex={dataIndex}
                    reference={reference}
                />
            )
        },
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#232323' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => reference && reference.current && reference.current.select());
            }
        }
    })
}

export default generateTableSearchProps