import React from "react";
import { BrowserRouter, Switch, Route, Link } from 'react-router-dom'
import {
    Upload,
    ModelList,
    Hierarchy,
    Result,
    Login,
    Logout,
    ForgotPassword,
    ResetPassword,
    NotForYouPage,
    ErrorPage,
    ResultList,
    Team,
    Model,
    ChangePass
} from "../../containers";
import {
    Loader,
    Header,
    PrivateRoute
} from '../../component';
import Conditionals from "../../containers/Conditionals/Conditionals";
import FilterContext from "../FilterContext/FilterContext";

const AppRouter = ({ }) => {
    const items = [
        {
            label: "Modelos RVI",
            to: "/",
            also: ["conditionals", "model"],
            allowed: ["admin", "manager"]
        },
        {
            label: "Lista de RVIs",
            to: "/resultlist",
            also: ["result"],
            allowed: ["authenticated"]
        },
        {
            label: "Jerarquía",
            to: "/hierarchy",
            also: ["team"],
            allowed: ["admin", "manager"]
        },
        {
            label: "Subida de datos",
            to: "/upload",
            allowed: ["admin"]
        },
        {
            label: "Cerrar sesión",
            to: "/auth/logout",
            allowed: ["admin", "manager", "authenticated"]
        },
    ]
    return (
        <BrowserRouter>
            <Loader />
            <Header items={items} logo={process.env.REACT_APP_LOGO}>
                <FilterContext />
            </Header>
            <Switch>
                <PrivateRoute path="/conditionals" allowed={["authenticated", "manager", "admin"]} exact component={Conditionals} />
                <PrivateRoute path="/upload" allowed={["admin"]} exact component={Upload} />
                <PrivateRoute path="/model/:id" allowed={["authenticated", "manager", "admin"]} exact component={Model} />
                <PrivateRoute path="/hierarchy" allowed={["authenticated", "manager", "admin"]} exact component={Hierarchy} />
                <PrivateRoute path="/resultlist" allowed={["authenticated", "manager", "admin"]} exact component={ResultList} />
                <PrivateRoute path="/resultlist/:userid" allowed={["manager", "admin"]} exact component={ResultList} />
                <PrivateRoute path="/result/:id/:userid" allowed={["authenticated", "manager", "admin"]} exact component={Result} />
                <PrivateRoute path="/team/:idcet" allowed={["authenticated", "manager", "admin"]} exact component={Team} />
                <Route path="/auth/login" exact component={Login} />
                <Route path="/auth/logout" exact component={Logout} />
                <Route path="/auth/forgot-password" exact component={ForgotPassword} />
                <Route path="/changePass" exact component={ChangePass} />
                <Route path="/auth/reset/:code" exact component={ResetPassword} />
                <Route path="/403" component={NotForYouPage} />
                <PrivateRoute path="/" allowed={["authenticated", "manager", "admin"]} exact component={ModelList} />
                {/* <Route path="/" exact component={HomePage} /> */}
                <Route path="" component={ErrorPage} />
            </Switch>
        </BrowserRouter>
    )
}

export default AppRouter;