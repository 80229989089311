import { gql } from '@apollo/client';

export const GET_MODELS = gql`
  query($uploadrvi: ID, $final: Boolean){
    models(limit: 0, where:{uploadrvi: $uploadrvi, final: $final}){
      id
      createdAt
      updatedAt
      name
      final
      conditions
      uploads
      activity
      role
      direction
      ready
      completed
      result_models{
        id
        kpis{
          kid
          amount
        }
      }
      kpis{
        id
        createdAt
        updatedAt
        kid
        name
        objetive
        resultkpi
        weight
        type
        inverse
        user{
          id
          username
          email
          UID
        }
     }
   }
  }
`;

export const GET_HIERARCHY = gql`
  query($uploadrvi: ID, $final: Boolean, $userID: ID!){
    user(id: $userID){
      id
      name
      lastname
      managerOf(where: {uploadrvi: $uploadrvi, final: $final}){
        id
        name
        level
        managers{
          id
          name
          lastname
        }
        workers{
          id
          name
          lastname
          role{
            type
          }
          kpis{
            id
            model{
              id
              name
            }
          }
          result_models{
            id
            model{
              id
            }
            kpis{
              kid
              amount
            }
            corrections
            validated
          }
        }
      }
    }
  }
`

export const GET_ADMIN_HIERARCHY = gql`
  query($uploadrvi: ID, $final: Boolean){
    teams(where: {uploadrvi: $uploadrvi, final: $final}){
      id
      name
      level
      managers{
        id
        name
        lastname
      }
      workers{
        id
        name
        lastname
        role{
          type
        }
        kpis{
          id
          model{
            id
            name
            final
          }
        }
        result_models{
          id
          model{
            id
          }
          kpis{
            kid
            amount
          }
          corrections
          validated
        }
      }
    }
  }
`

export const GET_MODEL = gql`
  query($model: ID!){
    model(id: $model){
      id
      name
      conditions
      ready
      completed
      final
      uploadrvi{
        id
      }
      result_models{
        id
        kpis{
          kid
          amount
        }
      }
      kpis{
        id
        createdAt
        updatedAt
        kid
        name
        objetive
        resultkpi
        weight
        type
        user{
          id
          username
          email
          UID
        }
      }
    }
  }
`

export const GET_KPIS = gql`
  query($model: ID){
    kpis(where:{model: $model}){
      id
      createdAt
      updatedAt
      kid
      name
      objetive
      resultkpi
      weight
      type
      user{
        id
        username
        email
        UID
      }
    }
  }
`

export const GET_MODELS_OF_USER = gql`
  query($user: ID){
    kpis(where:{user: $user}){
      model{
        id
        name
        final
        uploadrvi{
          id
          date_start
          date_end
        }
      }
      user{
        id
        name
        lastname
        email
        workerOf{
          id
          name
        }
        result_models{
          amount_rvi
          result
          kpis{
            kid
            amount
          }
          corrections
          validated
          model{
            id
          }
        }
      }
    }
  }
`

export const GET_UPLOAD_RVIS = gql`
  query{
    uploadrvis(sort: "date_start:desc"){
      id
      created_user{
        id
        username
      }
      date_start
      date_end
      files
    }
  }
`

export const GET_TEAMS = gql`
  query($uploadrvi: ID, $final: Boolean, $model: ID){
    teams(limit: 0, where:{uploadrvi: $uploadrvi, final: $final}){
      id
      name
      final
      teamID
      level
      managers{
        id
        name
        lastname
      }
      workers{
        id
        email
        name
        lastname
        role{
          type
        }
        kpis(where: {model: $model}){
          id
          name
          model{
            id
          }
        }
      }
      sons{
          id
          name
          final
          teamID
          managers{
            id
          }
          workers{
            id
            email
            name
            lastname
            kpis(where: {model: $model}){
              id
              name
            }
          }
      }
    }
  }
`

export const GET_SINGLE_TEAM = gql`
  query($uploadrvi: ID, $final: Boolean, $teamID: String){
    teams(limit: 0, where:{id: $teamID, uploadrvi: $uploadrvi, final: $final}){
      id
      name
      final
      teamID
      level
      image
      uploadrvi{
        id
        date_start
        date_end
      }
      managers{
        id
        name
        lastname
        email
      }
      fathers{
        id
        name
        teamID
        level
        final
        managers{
          id
        }
      }
      sons{
        id
        name
        final
        teamID
        level
        managers{
          name
          lastname
          result_models{
            id
            validated
            kpis{
              kid
              amount
            }
            model{
              id
              name
              final
              uploadrvi{
                id
              }
            }
          }
        }
        workers{
          id
          result_models{
            id
            validated
            kpis{
              kid
              amount
            }
            model{
              id
              final
              name
              uploadrvi{
                id
              }
            }
          }
        }
        fathers{
          id
          level
        }
      }
      workers{
        id
        email
        name
        lastname
        UID
        image{
          url
        }
        role{
          type
        }
        kpis{
          id
          name
          model{
            id
            final
            name
            uploadrvi{
              id
            }
          }
        }
        result_models{
          id
          validated
          model{
            id
            name
            final
            uploadrvi{
              id
            }
          }
          corrections
          validated
          result
          amount_rvi
          kpis{
            kid
            amount
          }
        }
      }
    }
  }
`

export const GET_ROLES = gql`
  query{
    roles{
      id
      type
      name
    }
  }
`
export const GET_USERS_JUST_UID = gql`
  query{
    users{
      id
      UID
      name
      lastname
      role{
        type
      }
      managerOf{
        id
      }
      workerOf{
        id
      }
    }
  }
`

export const GET_WORKDAYS = gql`
  query{
    workdays{
      id
    }
  }
`

export const GET_SINGLE_MODEL = gql`
  query($model: ID!){
    model(id: $model){
      id
      name
      kpis{
        kid
        name
        resultkpi
        weight
        type
        inverse
        base
        workday
        absences
        objetive 
        user{
          id
        }
      }
    }
  }
`

export const GET_SINLE_USER = gql`
query($id: ID!){
  user(id: $id){
    id
    UID
    name
    lastname
    email
  }
}
`

export const GET_MODELS_KPIS = gql`
  query{
    models{ id }
    kpis{ id }
  }
`


export const GET_HISTORY = gql`
  query($userid: ID!){
    resultModels(where: {user: $userid}, sort: "createdAt:DESC"){
      id
      amount_rvi
      result
      corrections
      createdAt
      kpis{
        kid
        amount
      }
      model{
        id
        uploadrvi{
          id
          date_start
          date_end
        }
      }
    }
  }
`
export const GET_RESULT = gql`
  query($id: ID!, $userid: ID!){
    model(id: $id){
        name
        activity
        direction
        role
        final
        uploadrvi{
            workdays(where: {user: $userid}){
                workdayfraction
                absences
            }
        }
        kpis(where:{user: $userid}){
            id
            kid
            name
            type
            achievement
            objetive
            resultkpi
            amount
            weight
            model{
              id
            }
            user{
                name
                lastname
                email
                workerOf{
                  id
                  name
                }
            }
        }
        result_models(where: {user: $userid}){
            id
            corrections
            result
            validated
            kpis{
              kid
              amount
            }
            model{
              id
              uploadrvi{
                id
                date_start
                date_end
              }
            }
        }
    }
}
`

export const GET_MASTER_TEAM = gql`
  query($uploadrvi: ID, $final: Boolean){
    teams(limit: 0, where:{ uploadrvi: $uploadrvi, final: $final, level: 0 }){
      id
      name
      uploadrvi{
        date_start
      }
      final
    }
  }
`

export const GET_MY_FATHERS = gql`
  query($uploadrvi: ID, $final: Boolean, $userid: ID!){
    user(id: $userid){
      id
      managerOf(where: {uploadrvi: $uploadrvi, final: $final}){
        id
        fathers{
          id
          managers{
            id
          }
        }
      }
    }
  }
`