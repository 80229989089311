import React from 'react'
import { Container } from '../../libraryComponent'

const NotForYouPage = () => {
    return (
        <Container>
            <h1>404</h1>
            <p>No hay nada por aquí</p>
        </Container>
    )
}

export default NotForYouPage